var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cabeca'),_c('dmiFilter',{attrs:{"dialog":this.$store.state.dialog}}),_c('v-container',{attrs:{"grid-list-xs":""}},[_c('v-card',{staticClass:"mx-auto pa-2 px-10 mb-10",attrs:{"outlined":"","color":"white"}},[_c('v-card-title',{staticClass:"pa-0",attrs:{"primary-title":""}},[_c('span',[_vm._v("Defect Maintenance Item Status")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-inner-icon":"mdi-account-search","dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"color":"info","depressed":"","small":"","route":"","to":"/newDmiRequest"}},[_vm._v("Add New DMI ")]),_c('v-btn',{staticClass:"mr-10",attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.$store.state.dialog = true}}},[_c('v-icon',[_vm._v("mdi-filter-menu")])],1),_c('download-excel',{attrs:{"header":"Defect Log List","name":"Users List","data":_vm.desserts}},[_c('v-btn',{attrs:{"color":"success","icon":""}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1),_c('v-btn',{attrs:{"color":"red","icon":""}},[_c('v-icon',[_vm._v("mdi-file-pdf")])],1),_c('v-btn',{attrs:{"color":"info","icon":""}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1)],1),_c('v-divider'),_c('v-card',{attrs:{"flat":"","height":"350"}},[_c('v-data-table',{attrs:{"height":"300","search":_vm.search,"fixed-header":"","dense":"","headers":_vm.headers,"items":_vm.$store.state.dmiRequest,"items-per-page":10},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","icon":"","color":"orange"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-edit")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","icon":"","color":"red white--text"},on:{"click":function($event){return _vm.remove(index, item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.toString().split(" ")[0])+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"px-2 py-0",attrs:{"label":"","color":(!item.closed_date || item.closed_date=='null' || item.closed_date==null)?_vm.getColor(item.data_discovery, _vm.getDate(item.due_date?item.due_date:(item.mel?item.mel:null), item.created_at)):'white black--text',"dark":""}},[_vm._v(" "+_vm._s(_vm.getDate(item.due_date?item.due_date:(item.mel?item.mel:null), item.created_at.toString().split(" ")[0]))+" ")])]}},{key:"item.op_impact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.op_impact=='1'||item.op_impact=='true')?'Yes':'No')+" ")]}},{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.file|| item.file=='undefined' || item.file=='null',"color":"success","icon":"","href":_vm.$store.state.path2 + item.file,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-file")])],1)]}},{key:"item.defect_no",fn:function(ref){
var item = ref.item;
return [(item.link_to_log)?_c('a',{attrs:{"target":"_blank","href":_vm.$store.state.path2+item.link_to_log}},[_vm._v(_vm._s(item.defect_no))]):_c('label',[_vm._v(_vm._s(item.defect_no))])]}},{key:"item.certificate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":(item.certificate == null)?true:false,"color":"success","small":"","icon":"","target":"_blank","link":"","href":item.certificate}},[_c('v-icon',[_vm._v("mdi-file-cad")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }