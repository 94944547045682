<template>
  <div>
    <cabeca></cabeca>

    <v-container class="d-flex flex-column">

      <div class="pa-5 pt-2 white">
        <v-row class="justify-space-between">
          <h5>Add/UPDATE AC INFORMATION</h5>
          <v-btn  small route to="/acInformation" color="info" class="elevation-0 ma-0 mb-2"> <v-icon class="mr-3">mdi-clipboard-list</v-icon> List</v-btn>
        </v-row>


        <v-divider></v-divider>

        <v-row>
          <v-col cols="6">
            <v-autocomplete
              label="Config"
              :items="$store.state.aircraftType"
              v-model="dados.config"
              item-text="designation"
              item-value="designation"
              outlined
              dense
            ></v-autocomplete>
            <v-text-field  v-model="dados.msn"  label="MSN" hint="MSN"  dense  flat outlined  ></v-text-field>
            <v-text-field  v-model="dados.reg_no"  label="REG NO" hint="REG NO"  dense  flat outlined  ></v-text-field>
            <v-text-field  v-model="dados.tail_no"  label="TAIL NO" hint="TAIL NO"  dense  flat outlined  ></v-text-field>
            <v-text-field v-model="dados.line_no"  label="LINE NO" hint="LINE NO"  dense  flat outlined  ></v-text-field>
            <v-text-field v-model="dados.eff_no"  label="EFF NO"   dense  flat outlined  ></v-text-field>
            <v-text-field v-model="dados.reg_expiracy" type="date" label="REG EXPIRY"   dense  flat outlined  ></v-text-field>
            <v-text-field v-model="dados.total_Hours"  type="number" label="TOTAL HOURS" hint="TOTAL HOURS"  dense flat outlined  ></v-text-field>
            <v-text-field v-model="dados.total_minutes"  type="number" label="TOTAL MINUTES" hint="TOTAL MINUTES"  dense flat outlined  ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="dados.total_cycles"  label="TOTAL CYCLES" hint="TOTAL CYCLES"  dense flat outlined  ></v-text-field>
            <v-text-field v-model="dados.ipc_no"  label="IPC NO" hint="IPC NO"  dense flat outlined  ></v-text-field>
            <v-text-field v-model="dados.mfg_date" type="date"  label="MFG DATE"   dense flat outlined  ></v-text-field>
            <v-text-field v-model="dados.log_date" type="date" label="LOG DATE"   dense  flat outlined  ></v-text-field>
             <v-checkbox  v-model="dados.active" label="ACTIVE" ></v-checkbox>
            <v-text-field v-model="dados.hours_day"  type="number" label="HOURS DAY" hint="HOURS DAY"  dense flat outlined  ></v-text-field>
            <v-text-field v-model="dados.cycles_day"  label="CYCLES DAY" hint="CYCLES DAY"  dense flat outlined  ></v-text-field>
            <v-text-field v-model="dados.current_status"  label="CURRENT STATUS" hint="CYCLES DAY"  dense flat outlined  ></v-text-field>
            <v-file-input v-model="file" label="CERTIFICATE" dense outlined prepend-icon="" prepend-inner-icon="mdi-file"></v-file-input>
            <v-row>
              <v-btn color="primary" class="elevation-0" @click="clear"> <v-icon class="mr-3">mdi-trash-can</v-icon> Clear</v-btn>
              <v-btn color="info" @click="save" class="elevation-0 ml-5"> <v-icon class="mr-3">mdi-content-save</v-icon> Save</v-btn>
              
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data() {
    return {

      edit:false,
      
      file:'', 
      config:[
        "777-200",
        "777-300",
        "777-700",
      ],      
      dados:{

       
        config: "",
        ac_type: "",
        msn: "",
        reg_no: "",
        tail_no: "",
        line_no: "",
        eff_no: "",
        ipc_no: "",
        mfg_date: "",
        log_date: "",
        total_hours: "",
        total_minutes: "",
        total_cycles: "",
        active: "",
        hours_day: "",
        cycles_day: "",
        reg_expiracy: "",
        certification: "",
      },
    
    };
  },
  methods:{

    clear(){

      this.dados ={

       
        config: "",
        ac_type: "",
        msn: "",
        reg_no: "",
        tail_no: "",
        line_no: "",
        eff_no: "",
        ipc_no: "",
        mfg_date: "",
        log_date: "",
        total_hours: "",
        total_minutes: "",
        total_cycles: "",
        active: "",
        hours_day: "",
        cycles_day: "",
        reg_expiracy: "",
        certification: "",
      }

    },
     // Funcao para salver users na bd
    save() {
      let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "acInformation";
      let dis = this;

      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

      const config = {
        headers: {
            'content-type': 'multipart/form-data'
          }
      }

      var form_data = new FormData();

      for ( var key in this.dados ) {
          form_data.append(key, this.dados[key]||'');
      }
      form_data.append('file',this.file);

      console.info(this.dados.certification)
      axios
        .post(url, form_data , config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating Entry",
              "error"
            );
          else {
            if (!dis.edit) {
              store.state.acInformation.push(response.data);
              Swal.fire(
                "Success!",
                dis.edit
                  ? "Entry Updated successfuly."
                  : "New Entry added successfuly. ",
                "success"
              );
              dis.clear();
            } else {
              store.dispatch('getAircraftInformation');
              Swal.fire(
                "Success!",
                dis.edit
                  ? "New Entry edited successfuly. "
                  : "Entry Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
    if(this.$store.state.editing){

      this.dados = this.$store.state.editingItem;
        this.edit = true;
      
    }

    this.$store.dispatch('getAcType')
        
  }
};
</script>

<style></style>
