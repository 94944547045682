<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
     
      <v-card>
        <v-card-title>
          <span class="headline">Log Search</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
                 class="py-0"
              >
              <v-autocomplete dense outlined  @change="getAcByType" label="A/C Type" :items="$store.state.aircraftType" item-text="designation" item-value="designation">
              </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
                 class="py-0"
              >
              <v-autocomplete dense outlined  multiple v-model="dados.reg_no" label="A/C Reg No" :items="$store.state.acInformation" item-text="reg_no" item-value="reg_no">
              </v-autocomplete>
              </v-col>
             
              <v-col cols="12" class="py-0">
                <v-text-field
                  label="Log Page No"
                  dense outlined v-model="dados.log_page_number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Date From (GMT)"
                  type="date"
                  dense outlined
                  v-model="dados.from"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" >
                 <v-text-field
                  label="After Flight No"
                  dense outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Date To (GMT)"
                  type="date"
                  dense outlined
                  v-model="dados.to"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" >
                 <v-text-field
                  label="After Flight No"
                  dense outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Departure City Code"
                  dense outlined
                  v-model="dados.department"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" >
                 <v-text-field
                  label="Arrival City Code"
                  dense outlined v-model="dados.arr"
                ></v-text-field>
              </v-col>
              

            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false, clear()"
          >
            Close
          </v-btn>
          <v-btn
            color="success darken-1"
            elevation="0"
            @click=" retrieveAll()"
          >
            Retrieve All
          </v-btn>
          <v-btn
            color="info darken-1"
            elevation="0"
            @click=" retrieve()"
          >
            Retrieve
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props:{
     /*  dialog:{
        type:Boolean,
        default:false,
      } */
    },
    data: () => ({
      /* dialog: false, */
      dados:{
        reg_no:'',
        log_page_number:'',
        from:'',
        to:'',
        department:'',
        arr:'',
      }
    }),
    methods:{
      clear(){
        this.dados = {
        reg_no:'',
        log_page_number:'',
        from:'',
        to:'',
        department:'',
        arr:'',
      };

      this.$store.state.dialog = false;
      },
      retrieve(){
        this.$store.dispatch("filterAircraftLog", this.dados);

        
          
          this.$store.state.dialog = false;
       
      },
      retrieveAll(){
        this.$store.dispatch("getAtl");

        /* setTimeout(() => {
          
          this.dialog = false;
        }, 500); */
         this.$store.state.dialog = false;
      },
      getAcByType(item){
         this.$store.dispatch('getAcByType', item);

         this.dados.reg_no = '';
      },
    },
    computed:{
      dialog(){
        return this.$store.state.dialog;
      }
    }
  }
</script>
