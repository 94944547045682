var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cabeca'),_c('v-container',{attrs:{"grid-list-xs":""}},[_c('v-card',{staticClass:"mx-auto pa-2 px-10 mb-10",attrs:{"outlined":"","color":"white"}},[_c('v-card-title',{staticClass:"pa-0",attrs:{"primary-title":""}},[_c('span',[_vm._v("Task Card List")]),_c('v-spacer'),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-inner-icon":"mdi-account-search","dense":"","outlined":"","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"color":"info","depressed":"","small":"","route":"","to":"/newTaskCard"}},[_vm._v("Add New AC ")]),_c('v-btn',{staticClass:"mr-10",attrs:{"color":"primary","icon":""}},[_c('v-icon',[_vm._v("mdi-filter-menu")])],1),_c('v-btn',{attrs:{"color":"red","icon":""}},[_c('label',{attrs:{"for":"file"}},[_c('v-icon',[_vm._v("mdi-file-import")]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","name":"","id":"file"},on:{"change":_vm.importExcell}})],1)]),_c('download-excel',{attrs:{"header":"Aircraft Information","name":"Users List","data":_vm.desserts}},[_c('v-btn',{attrs:{"color":"success","icon":""}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1),_c('v-btn',{attrs:{"color":"red","icon":""}},[_c('v-icon',[_vm._v("mdi-file-pdf")])],1),_c('v-btn',{attrs:{"color":"info","icon":""}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1)],1),_c('v-divider'),_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"search":_vm.search,"fixed-header":"","dense":"","headers":_vm.headers,"items":_vm.desserts,"items-per-page":10},scopedSlots:_vm._u([{key:"item.options",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","icon":"","color":"orange"},on:{"click":function($event){return _vm.editar(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-edit")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","icon":"","color":"red white--text"},on:{"click":function($event){return _vm.remove(index, item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}},{key:"item.status",fn:function(){return [_c('div',{staticClass:" info rounded-circle",staticStyle:{"width":"18px","height":"18px"}})]},proxy:true},{key:"item.card_no",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"color":"info--text","small":"","target":"_blank","href":_vm.$store.state.path2 + item.card_file_upload}},[_vm._v(_vm._s(item.card_no))])]}},{key:"item.upload",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","disabled":"","small":"","icon":""},on:{"click":function($event){return _vm.upload(item.id)}}},[_c('v-icon',[_vm._v("mdi-file-upload")])],1)]}},{key:"item.treshould",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tirarBarra([item.fh_t,item.fc_t, item.da_t, item.mo_t, item.ye_t]))+" ")]}},{key:"item.repeat",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tirarBarra([item.fh_r,item.fc_r, item.da_r, item.mo_r, item.ye_r]))+" ")]}},{key:"item.custom",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.tirarBarra([item.fh_c,item.fc_c, item.da_c, item.mo_c, item.ye_c]))+" ")]}},{key:"item.history",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"success","disabled":"","small":"","icon":""},on:{"click":function($event){return _vm.history(item.id)}}},[_c('v-icon',[_vm._v("mdi-cube")])],1)]}},{key:"item.certificate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":(item.certificate == null)?true:false,"color":"success","small":"","icon":"","target":"_blank","link":"","href":_vm.$store.state.path2 + item.certificate}},[_c('v-icon',[_vm._v("mdi-file-cad")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }