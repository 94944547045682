<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" >

        <v-card-title class="px-0" primary-title>
            DEFERRED MAINTENANCE ITEM REQUEST
        </v-card-title>
        <v-divider></v-divider>


        <v-row>

               <v-col cols="6">
                    <v-text-field
                    label="Airline DMI number"
                    dense
                    outlined
                    v-model="dados.airline_dmi_no"
                    ></v-text-field>
               </v-col>
               <v-col cols="6">
                   <v-autocomplete label="REG No" v-model="dados.reg_no" dense outlined :items="$store.state.acInformation" item-value="reg_no" item-text="reg_no">
                </v-autocomplete>
               </v-col>
                
                
                <v-col cols="6">
                    <v-autocomplete label="ATA" dense v-model="dados.ata" outlined :items="$store.state.ata" item-value="chapter" item-text="chapter">
                    <template v-slot:item="{item}">
                        <v-list-item-content >
                            {{item.chapter}} - {{item.designation}}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
                </v-col>

                <v-col cols="6">
                    <v-text-field
                    label="STATION"
                    dense
                    outlined
                    v-model="dados.station"
                ></v-text-field>

                </v-col>

                <v-col :cols="edit?3:4">
                    <v-text-field
                    label="Date of Discovery"
                    type="date"
                    dense
                    outlined
                    v-model="dados.data_discovery"
                ></v-text-field>
                </v-col>
                <v-col :cols="edit?3:4" class="align-center justify-center d-flex">
                    <v-checkbox label="Has Operational Impact?" class="my-0 py-0 " v-model="dados.op_impact" ></v-checkbox>

                </v-col>

                <v-col :cols="edit?3:4">
                      <v-text-field
                            label="Trasfered From"
                            dense
                            outlined
                            v-model="dados.transfered_from"
                        ></v-text-field>
                </v-col>
                <v-col v-if="edit">
                    <v-text-field
                  
                    label="Date of Discovery"
                    type="date"
                    dense
                    outlined
                    v-model="dados.closed_date"
                ></v-text-field>
                </v-col>
                
                 
                 
               <v-col cols="12">
                    <v-radio-group v-model="dados.category" row class="py-0 my-0" >
                    <v-col class=" d-flex pa-0 ma-0" cols="4">
                        <v-radio class="pa-0 ma-0 mb-5 mr-2" @click="mel = true, dueDate = false, dados.due_date = ''" label="MEL" value="MEL"></v-radio>
                        <v-autocomplete :disabled="!mel" v-model="dados.mel" dense outlined :items="['A','B','C','D']" >
                        </v-autocomplete>
                    </v-col>
                    <v-col class=" d-flex pa-0 ma-0" cols="4">
                        <v-radio class="pa-0 ma-0 mb-5 mr-2 ml-5"  @click="mel = false, dueDate = true, dados.mel = ''" label="Due date" value="DUE DATE"></v-radio>
                        <v-text-field
                           :disabled="!dueDate"
                            type="date"
                            dense
                            outlined
                            v-model="dados.due_date"
                        ></v-text-field>
                    </v-col>
                    <v-col class="d-flex pa-0 ma-0 ml-0" cols="4">
                        <v-radio class="pa-0 ma-0 mb-5 mr-2 ml-10"  @click="mel = false, dueDate = false, dados.mel = '',dados.due_date = '' "  v-model="dados.no_due_date" label="No Due Date" value="No DUE DATE"></v-radio>
                    </v-col>
                    
                </v-radio-group>
               </v-col>

                <v-col cols="6" class="my-0 py-0">
                     <v-textarea
                        outlined dense
                        label="Description"
                        height="150"
                        v-model="dados.description"
                    ></v-textarea>
                </v-col>
                <v-col cols="6" class="my-0 py-0">
                      <v-textarea
                            outlined dense
                            label="Reason"
                            height="150"
                            v-model="dados.reason"
                        ></v-textarea>
                </v-col>
              
               
                <v-col cols="12">

                     <v-row>
                   <v-col cols="4">
                       <v-text-field
                    label="Manual Reference"
                    dense
                    outlined
                     v-model="dados.manual_ref"
                ></v-text-field>
                   </v-col>
                   <v-col cols="4">
                       <v-text-field
                    label="Recomended By"
                    dense
                    outlined
                     v-model="dados.recomended_by"
                ></v-text-field>
                   </v-col>
                   <v-col cols="4">
                       <v-text-field
                    label="Special Tool Required"
                    dense
                    outlined
                     v-model="dados.special_tools_required"
                ></v-text-field>
                </v-col>
               </v-row>

                </v-col>
             

              

                <v-col cols="12">
                    <v-textarea
                    outlined dense
                   label="Parts Requisition"
                   height="150"
                   v-model="dados.parts"
               ></v-textarea>
                </v-col>
                <v-col cols="6">
                    <v-file-input
                        dense outlined
                        label="File"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        v-model="file"
                    ></v-file-input>
                </v-col>


               <v-row class="justify-end">
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" elevation="0" class="ml-2">Save</v-btn>
                </v-row>
               

           
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{

          dueDate:false,
          mel:false,
          thisLogIsForMaintenance:'',
          edit:false,

          dados:{
                 internalId: '',
                airline_dmi_no: '',
                issued_by: '',
                reg_no: '',
                ata: '',
                station: '',
                data_discovery: '',
                mel: true,
                category: '',
                due_date: '',
                no_due_date: '',
                close_date: '',
                complete_date: '',
                closed_date: '',
                op_impact: '',
                transfered_from: '',
                description: '',
                reason: '',
                manual_ref: '',
                recomended_by: '',
                special_tools_required: '',
                parts: '',
                }
      }
  },
  methods:{
      clear(){
          this.dados={
                 internalId: '',
                airline_dmi_no: '',
                issued_by: '',
                reg_no: '',
                ata: '',
                station: '',
                data_discovery: '',
                mel: true,
                category: '',
                due_date: '',
                no_due_date: '',
                close_date: '',
                complete_date: '',
                closed_date: '',
                op_impact: '',
                transfered_from: '',
                description: '',
                reason: '',
                manual_ref: '',
                recomended_by: '',
                special_tools_required: '',
                parts: '',
                }
      },
    // Funcao para salver users na bd
    save() {


        let store = this.$store;
      store.dispatch("loading")
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "dmiRequest";
      let dis = this;

      url += this.edit ? ("/"+this.dados.id.toString()) + "?_method=PATCH" : "";

       const config = {
        headers: {
            'content-type': 'multipart/form-data'
            }
        }

        var form_data = new FormData();

        for ( var key in this.dados ) {
            form_data.append(key, this.dados[key]||'');
        }
        form_data.append('file',this.file);

      axios
        .post(url, form_data, config)
        .then(function(response) {
          store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating DMI",
              "error"
            );
          else {
            if (!dis.edit) {
              store.dispatch('getDmiRequest');
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New DMI Updated successfuly. "
                  : "DMI added successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New DMI edited successfuly. "
                  : "DMI Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
  mounted(){
      this.$store.dispatch('getAircraftInformation');
      this.$store.dispatch('getAta');

       if(this.$store.state.editing){

        this.dados = this.$store.state.editingItem;
        this.edit = true;

      }
  }
  

}
</script>

<style>

</style>