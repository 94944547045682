<template>
  <div>
    <cabeca></cabeca>

    <v-container grid-list-xs>
      <v-card class="mx-auto pa-2 px-10 mb-10" outlined color="white">
        <v-card-title class="pa-0" primary-title>
          <span>User Information Setup</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-account-search"
            dense
            outlined
            label="Search"
            class="ma-0 pa-0"
          ></v-text-field>
          <v-row class="justify-end">
            <download-excel
              header="User Information Setup"
              name="Users List"
              :data="desserts"
              ><v-btn color="success" icon
                ><v-icon>mdi-file-excel</v-icon></v-btn
              ></download-excel
            >
            <v-btn color="red" icon><v-icon>mdi-file-pdf</v-icon></v-btn>
            <v-btn color="info" icon><v-icon>mdi-printer</v-icon></v-btn>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-tabs v-model="tab">
          <v-tabs-slider color="info"></v-tabs-slider>
          <v-tab class="info--text" :key="0">
            <v-icon class="mr-2">mdi-clipboard-list</v-icon> List
          </v-tab>
          <v-tab class="info--text" :key="1">
            <v-icon class="mr-2">mdi-account-edit</v-icon> Add
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat height="350">
              <v-data-table
                height="300"
                :search="search"
                fixed-header
                dense
                :headers="headers"
                :items="desserts"
                :items-per-page="5"
              >
                <template v-slot:item.options="{ item, index }">
                  <v-btn
                    @click="(dados = item), (edit = true), (tab = 1)"
                    small
                    icon
                    class="mx-2"
                    color="orange"
                    ><v-icon>mdi-account-edit</v-icon></v-btn
                  >
                  <v-btn
                    @click="remove(index, item)"
                    small
                    icon
                    class="mx-2"
                    color="red white--text"
                    ><v-icon>mdi-trash-can</v-icon></v-btn
                  >
                </template>
                <template v-slot:item.accessLevel="{ item }">
                  {{ accessLevelFromInt(item.accessLevel) }}
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat height="350">
              <v-divider></v-divider>
              <v-row class="mt-2">
                <v-col cols="6" class="py-0">
                  <v-text-field
                    prepend-inner-icon="mdi-card-text"
                    type="text"
                    label="User Name"
                    v-model="dados.username"
                    dense
                    outlined
                  ></v-text-field>
                  <v-text-field
                    prepend-inner-icon="mdi-card-text"
                    type="text"
                    label="Full Name"
                    v-model="dados.fullName"
                    dense
                    outlined
                  ></v-text-field>
                  <v-text-field
                    prepend-inner-icon="mdi-card-text"
                    type="email"
                    label="Email"
                    v-model="dados.email"
                    dense
                    outlined
                  ></v-text-field>
                  <v-text-field
                    prepend-inner-icon="mdi-card-text"
                    label="Password"
                    type="password"
                    v-model="dados.password"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="py-0">
                  <v-autocomplete
                    prepend-inner-icon="mdi-card-text"
                    :items="accessLevel"
                    item-text="label"
                    item-value="value"
                    label="Access Level"
                    v-model="dados.accessLevel"
                    dense
                    outlined
                  ></v-autocomplete>
                  <v-autocomplete
                    prepend-inner-icon="mdi-card-text"
                    :items="department"
                    label="Deparment"
                    v-model="dados.department"
                    dense
                    outlined
                  ></v-autocomplete>
                  <v-text-field
                    prepend-inner-icon="mdi-card-text"
                    type="date"
                    label="Expiration Date"
                    v-model="dados.expirationdate"
                    dense
                    outlined
                  ></v-text-field>
                  <v-btn
                    color="primary"
                    elevation="0"
                    class="mx-2"
                    @click="clear(), (edit = false), (tab = 0)"
                  >
                    <v-icon class="mr-2">mdi-trash-can</v-icon> Cancel</v-btn
                  >
                  <v-btn color="info" elevation="0" class="mx-2" @click="save">
                    <v-icon class="mr-2">mdi-content-save</v-icon> Save</v-btn
                  >
                </v-col>
              </v-row>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },

  data() {
    return {
    // dados a serem enviados para o servidor.
      dados: {
        username: null,
        password: null,
        fullName: null,
        accessLevel: 0,
        email: null,
        department: null,
        expirationdate: null,
      },
      tab: null,
      search: "",
      edit: false,
    //   Lista de niveis de acesso
      accessLevel: [
        { label: "None", value: 0 },
        { label: "Read", value: 1 },
        { label: "Write", value: 2 },
        { label: "All", value: 3 },
      ],
      department: ["MCC"],
    //   Cabecalhos da tabela de users
      headers: [
        {
          text: "Username",
          align: "start",
          sortable: false,
          value: "username",
          class: "info white--text",
        },
        { text: "Full Name", value: "fullName", class: "info white--text" },
        {
          text: "Access Level",
          value: "accessLevel",
          class: "info white--text",
           width:140,
        },
        { text: "Email", value: "email", class: "info white--text" },
        { text: "Department", value: "department", class: "info white--text",  width:140,},
        {
          text: "Expiration Date",
          value: "expirationdate",
          class: "info white--text",
          width:140,
        },
        { text: "Created At", value: "createdAt", class: "info white--text" },
        { text: "Options", value: "options", class: "info white--text", width:120},
      ],
    };
  },
  computed: {
    //   Dados da tabela que partem do vetor no store
    desserts() {
      return this.$store.state.users;
    },
  },
  mounted() {
    if (this.$store.state.users.length <= 0) {
      let store = this.$store;
      let axios = require("axios");

      axios
        .get(this.$store.state.path + "users")
        .then(function(response) {
          store.state.users = response.data.data;
          /*  console.info(response); */
        })
        .catch(function(error) {
          // handle error
          const Swal = require("sweetalert2");
          Swal.fire(
            "Oops...",
            "There was an erro when trying to get the users.",
            "error"
          );
          console.info(error);
        })
        .then(function() {
          // always executed
        });
    }
  },
  methods: {
    //   Funcao que converto os acessos de numeros para texto
    accessLevelFromInt(level) {
      switch (level) {
        case 0:
          return "None";
        case 1:
          return "Read";
        case 2:
          return "Write";
        case 3:
          return "All";
        default:
          return "None";
      }
    },
    // funcao para limpar todos os campos
    clear() {
      this.dados = {
        username: null,
        password: null,
        fullName: null,
        accessLevel: 0,
        email: null,
        department: null,
        expirationdate: null,
      };
      this.edit = false;
    },
    // funcao que elimina um registo
    delete(index, item) {
      let store = this.$store;
      let axios = require("axios");
      const Swal = require("sweetalert2");

      axios
        .delete(this.$store.state.path + "users/" + item.id.toString())
        .then(function() {
          store.state.users.splice(index, 1);
          Swal.fire(
            "Success!",
            item.fullName + " removed successfuly.",
            "success"
          );
        })
        .catch(function(error) {
          Swal.fire("Oops...", "Unable to delete. " + error, "error");
          console.info(error);
        });
    },
    //Este metodo é executado antes de se eliminar um registo.
    remove(index, item) {
      let dis = this;
      const Swal = require("sweetalert2");

      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this data!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          dis.delete(index, item);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "This user account is is safe :)", "error");
        }
      });
    },
    // Funcao para salver users na bd
    save() {
      let store = this.$store;
      let axios = require("axios");
      const Swal = require("sweetalert2");

      let url = this.$store.state.path + "users";
      let dis = this;

      url += this.edit ? this.dados.id.toString() + "?_method=PATCH" : "";

      axios
        .post(url, this.dados)
        .then(function(response) {
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating users",
              "error"
            );
          else {
            if (!dis.edit) {
              store.state.users.push(response.data);
              Swal.fire(
                "Success!",
                dis.edit
                  ? "New User added successfuly. "
                  : "User Updated successfuly.",
                "success"
              );
            } else {
              Swal.fire(
                "Success!",
                dis.edit
                  ? "New User edited successfuly. "
                  : "User Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
  },
};
</script>

<style></style>
