<template>
     <div>
    <cabeca></cabeca>

    
     <v-container grid-list-xs>
         <v-card
        class="mx-auto pa-2 px-10 mb-10"
        outlined
        color="white" min-height="450">

        <v-card-title class="px-0 d-flex flex-wrap" primary-title>
            
            <v-col cols="12" class="pa-0 ma-0">
                   <p class="pr-2 pa-0 ma-0">AIRCRAFT CHECK HISTORY</p>
            </v-col>
        </v-card-title>
        <v-divider></v-divider>

        <v-row>
            <v-col cols="2" class="pa-0 ma-0">
              <v-list >
                    <v-list-item   class="pa-0 ma-0" v-for="(item, i) in items" :key="i" @click="getPackages(item.reg_no)">
                        <v-list-item v-text="item.reg_no"></v-list-item>
                    </v-list-item>
                <!--  <v-list-group sub-group no-action v-for="(children,e) in item.children" :key="children.designation" >
                    <template v-slot:activator>
                    <v-list-item-content  @click="check_level = children.designation">
                            <v-list-item-title>{{children.designation}}</v-list-item-title>
                    </v-list-item-content>
                    </template>

                        <v-list-item
                            v-for="child in filhos[e]"
                            :key="child.sequence"
                            link
                            @click="getTaskCards(child.sequence)"
                        >
                        {{child.sequence}}
                        </v-list-item> 
                 </v-list-group>
 -->

               
                


                </v-list>
            </v-col>
            <v-col cols="10" class="grey lighten-4 pa-0">
              
                 <v-data-table
                 class="grey lighten-4"
                    
                    :search="search"
                    fixed-header
                    dense
                    :headers="headers"
                    :items="desserts"
                    :items-per-page="10"
                >
                    <template class="pa-0 ma-0"  v-slot:item.nex_due="{item}">
                        <v-chip :class="getColor(item.output_date, item.nex_due)"> {{item.nex_due}}</v-chip>
                    </template>
                 
                    <template class="pa-0 ma-0"  v-slot:item.sign_off_doc="">
                       <v-btn flat icon color="primary" disabled>
                           <v-icon>mdi-file</v-icon>
                       </v-btn>
                    </template>
                 
                </v-data-table>

                <v-row class="pa-3">
                    <v-spacer></v-spacer>
                    <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                    <v-btn color="primary" @click="save" elevation="0" class="ml-2">Save</v-btn>
                </v-row>

                
                 
            </v-col>
        </v-row>

        </v-card>
  
     </v-container> 
    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  data(){
      return{
    
        items:[], 

        filhos:[],

        search:null,
           headers: [
          {
            text: 'LEVEL', align: 'start', value: 'level', class: "info white--text", width:130,
            
          },
          { text: 'SEQ', value: 'sequence',class: "info white--text", width:90,},
          { text: 'INPUT DATE', value: 'iput_date', class: "info white--text", width:120, },
          { text: 'OUTPUT DATE', value: 'output_date' ,class: "info white--text", width:130,},
          { text: 'AC TIME',  value: 'ac_time',class: "info white--text", width:100, },
          { text: 'AC CYCLES',  value: 'ac_cycles',class: "info white--text", width:140, },
          { text: 'NEXT DUE',  value: 'nex_due',class: "info white--text", width:140, },
          { text: 'HOURS',  value: 'hours',class: "info white--text", width:120, },
          { text: 'CYCLES',  value: 'cycles',class: "info white--text", width:120, },
          { text: 'DAYS',  value: 'days',class: "info white--text", width:120, },
          { text: 'STN',  value: 'stn',class: "info white--text", width:90, },
          { text: 'PROJECT',  value: 'project',class: "info white--text", width:200, },
          { text: 'CHECK DEF',  value: 'check_def',class: "info white--text", width:120, },
          { text: 'SIGN OFF DOC',  value: 'sign_off_doc',class: "info white--text", width:123, },
        ],
        desserts:[],
        taskPackage:null,
        reg_no:null,
        check_level:null,


        cards:[],

   
      }
  },
  
  methods:{
        getPackages(item){

            let dis = this;
               dis.$store.dispatch("loading")
            const axios = require('axios');
            axios.post(this.$store.state.path + 'aircraft_check_history_byReg_no', {reg_no: item}).then((response)=>{
                dis.desserts = response.data;
                   dis.$store.dispatch("nloading")
            });

        },
        getColor (init,end) {
            if (this.percentagemFromDates(init, end) >= 100) return 'red'
            else if (this.percentagemFromDates(init, end) < 100 && this.percentagemFromDates(init, end)>=60) return 'orange'
            else return 'green white--text'
        },

      getDate(data, discovery){

        let dataFinal = data;

       if(data == "C"){
          var date = new Date(discovery);
          date.setDate(date.getDate() + 2);
          var mes = date.getMonth()+1;
          dataFinal =  date.getFullYear() + "-" + ((mes.toString().length<2)?'0'+mes:mes) + '-' + ((date.getDate().toString().length<2)?'0'+date.getDate():date.getDate());
       }

       return dataFinal;

      
      },
       percentagemFromDates(s, e){
        var start = new Date(s);
        var end = new Date(e);
        var today = new Date();

        //use Math.abs to avoid sign
        var q = today-start;
        var d = end-start;

        /*  console.info('Porcentagem', Math.round(((q/d))*100)) */
        return ((q/d))*100;
     
    },
 
      listarAeronaves(){
          const axios = require('axios');

          let dis = this;

           

           let tmp =  this.$store.state.acInformation;

          axios.get(this.$store.state.path + 'maintenance_program').then((response)=>{
             tmp.forEach(element => {
                  element.children  = response.data.data;

                  element.children.forEach(el => {
                       dis.getPackage(el)
                  });

                 
              });
              
            dis.items =  tmp;
          });

        
      },
      
      getPackage(item){


          let dis = this;
           dis.$store.dispatch("loading")
          const axios = require('axios');

            axios.post(this.$store.state.path+'aircraft_checkPackage', {check_level:item.designation}).then((response)=>{
              
              dis.filhos.push(response.data);

               dis.$store.dispatch("nloading")
          });

      },
      getTaskCards(sequence){


          let dis = this;
          dis.$store.dispatch("loading")
          dis.desserts = [];
          dis.taskPackage = sequence;
          const axios = require('axios');

            axios.post(this.$store.state.path+'getMplan_taskcard', {program:sequence}).then((response)=>{

                response.data.forEach(element => {
                    dis.desserts.push(element[0]);
                    console.log(element[0])
                });


                dis.$store.dispatch("nloading")
              
             
          });

      },
    
      getNexDate(currentDate, days){

          var date1 = new Date(currentDate.split(' ')[0]);
          date1.setDate(date1.getDate()+ parseInt(days) );
          return date1.getFullYear()+  "-" + (date1.getMonth()+1) + "-"+ date1.getDate();
      }
      
  },
  async mounted(){
      let dis = this;
      this.$store.dispatch('getAircraftInformation').then(()=>{
          dis.listarAeronaves();
      });

      this.$store.dispatch('getTaskCard');
        
  }
  

}
</script>

<style>

</style>