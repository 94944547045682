<template>
   <v-navigation-drawer
        permanent
       
        fixed
            expand-on-hover
            width="300"
      >
        <v-list>
          <v-list-item class="px-2">
            
          </v-list-item>

          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title class="title">
                TAAG-VME
              </v-list-item-title>
              <v-list-item-subtitle>www.taagvme.com</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        

        <v-divider></v-divider>
        

                    <v-list-item
                        route to="/">

                        <v-list-item-icon>
                        <v-icon >mdi-home</v-icon>
                        </v-list-item-icon>
                    
                        <v-list-item-title v-text="'Home'"></v-list-item-title>

                    </v-list-item>
              

                <v-list-group  :value="false" no-action v-for="menu in menus" :key="menu.label" >
                <template v-slot:activator>
                    <v-list-item-icon>
                    <v-icon >{{menu.icon}}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                    <v-list-item-title>{{menu.label}}</v-list-item-title>
                    </v-list-item-content>
                    
                </template>

                <v-list-group
                v-show="menu.val1[0].val"
                :value="false"
                    no-action
                    sub-group
                    v-for="item in menu.val1" :key="item.label" 
                    >
                    <template v-slot:activator>
                        <v-list-item-content>
                        <v-list-item-title>{{item.label}}</v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <v-list-item
                        v-show="item.val"
                        v-for="btn in item.val"
                        :key="btn.label"
                        route :to="btn.route">
                    
                        <v-list-item-title v-text="btn.label"></v-list-item-title>

                    </v-list-item>

                </v-list-group>
                  
                  <v-list-item
                         v-show="!menu.val1[0].val"
                        v-for="btn1 in menu.val1"
                        :key="btn1.label"
                        route :to="btn1.route">
                    
                        <v-list-item-title v-text="btn1.label"></v-list-item-title>

                </v-list-item>
                

                
            </v-list-group>

             <v-list-item
                        route to="/tabelas">

                         <v-list-item-icon>
                        <v-icon >mdi-table</v-icon>
                        </v-list-item-icon>
                    
                        <v-list-item-title v-text="'Tabelas'"></v-list-item-title>

            </v-list-item>




        <!-- <v-expansion-panels accordion elevation="0" class="pa-0 ma-0" focusable >
            <v-expansion-panel v-for="menu in menus" :key="menu.label" class="pa-0 ma-0" elevation="0">
                <v-expansion-panel-header class="pa-0 pl-2" elevation="0"><v-icon class="mx-3" style="max-width:35px;">{{menu.icon}}</v-icon>  {{menu.label}}</v-expansion-panel-header>
                <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
                        <v-expansion-panels accordion class="pa-0 ma-0" elevation="0" focusable>
                            <v-expansion-panel v-for="item in menu.val1" elevation="0" :key="item.label" class="pa-0 ma-0" >
                                <v-expansion-panel-header elevation="0" class="pa-0 ma-0">{{item.label}}</v-expansion-panel-header>
                                <v-expansion-panel-content elevation="0" class="pa-0 ma-0">

                                    <v-list nav dense v-for="btn in item.val" elevation="0" :key="btn.label" class="pa-0 ma-0 elevation-0">
                                        <v-list-item route :to="btn.route" elevation="0">
                                            <v-list-item-title>{{btn.label}}</v-list-item-title>
                                        </v-list-item>
                                        
                                        </v-list>
                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels> -->

      
      </v-navigation-drawer>
</template>

<script>
export default {

   data:()=>{
       return{
            menus: [
        {
          label:  "fleetActivity",
          icon: "mdi-airplane",
          val1: [
            {
              label: "flightData",
              route: "/flightData",
              val: [
                { label: "acInformation", route: "/acInformation" },
                { label: "New Log Entry", route: "/newLogEntry" },
                { label: "Log Time Correction", route: "/aircraftLog" },
                { label: "Aircraft Log", route: "/aircraftLog" },
                { label: "Aircraft Hours/Cycles", route: "/aircraftHoursCycles" },
                { label: "APU Hours/Cycles", route: "/apuHoursCycles" },
                { label: "Oil & Fuel Consuption", route: "/oilFuelConsuption" },
                { label: "AC Schendule", route: "/acSchendule" },
                { label: "Route Assignment", route: "/routeAssignment" },
                { label: "ATL", route: "/atl" },
              ],
            },
            { label: "Defect Log", route: "/acInformation",
              val:[
                {label: "Defect Log Entry", route:"/defectLogEntry"},
                {label: "Defect Log Info Search", route:"/defectLogInfoSearch"},
                {label: "New DMI Request", route:"/newDmiRequest"},
                {label: "DMI Request List", route:"/dmiRequestList"},
                {label: "Outstanding DMI List", route:"/defectLogInfoSearch"},
                {label: "DMI Status Update", route:"/defectLogInfoSearch"},
                {label: "Sign Off Copy Missing List", route:"/defectLogInfoSearch"},
              ]
            },
            { label: "Part Times", route: "/partTimes", val:[
              { label: "D2-TBF", route: "/tbf" },
              { label: "D2-TBG", route: "/tbf" },
              { label: "D2-TBH", route: "/tbf" },
              { label: "D2-TBJ", route: "/tbf" },
              { label: "D2-TBK", route: "/tbf" },
              { label: "D2-TED", route: "/tbf" },
              { label: "D2-TEE", route: "/tbf" },
              { label: "D2-TEF", route: "/tbf" },
              { label: "D2-TEG", route: "/tbf" },
              { label: "D2-TEH", route: "/tbf" },
              { label: "D2-TEI", route: "/tbf" },
              { label: "D2-TEJ", route: "/tbf" },
              { label: "D2-TEK", route: "/tbf" },
            ] },
            { label: "Cabine Defects", route: "/acInformation", val:[
              { label: "CDL", route: "/tbf" },
              { label: "Open Defects Report", route: "/tbf" },
              { label: "Cabine Defects Search", route: "/tbf" },
              { label: "Close Deffered Items", route: "/tbf" },
            ] },
            
          ],
        },
        {
          label: "Maintenance",
          icon: "mdi-hammer-screwdriver",
          val1: [
            { label: "Maintenance Schedule", 
                val: [
                  { label: "Maintenance Tracking", route: "/" },
                  { label: "Route Package", route: "/" },
                  { label: "Route Information", route: "/" },
                ], 
              },
            { label: "Maintenance Program", 
                val: [
                  { label: "New MR Item", route: "/" },
                  { label: "New Task Card", route: "/newTaskCard" },
                  { label: "MR Items List", route: "/" },
                  { label: "MR Compliance", route: "/" },
                  { label: "TC Revision Control", route: "/" },
                  { label: "Revision Pending List", route: "/" },
                  { label: "Task Card List", route: "/taskCardList" },
                  { label: "MR-TC Cross Reference", route: "/" },
                  { label: "OOP Forecast", route: "/" },
                  { label: "Task Card Properties*", route: "/" },
                  { label: "TC Last Done", route: "/" },
                ],
              },
            { label: "Maintenance Checks", 
                val: [
                  { label: "Check Planning", route: "/" },
                  { label: "Check History", route: "/" },
                  { label: "Check Package", route: "/" },
                  { label: "Check Sign Off", route: "/" },
                  { label: "Check Pakage Preload", route: "/" },
                  { label: "Next Maintenance Check", route: "/" },
                  { label: "Minimum Package Setup", route: "/" },
                  { label: "Check Definition", route: "/" },
                  { label: "Access Panel List", route: "/" },
                ],
              },
            { label: "Maintenance Order", 
                val: [
                  { label: "New Order", route: "/" },
                  { label: "Issue Order", route: "/" },
                  { label: "Outstanding Order Search", route: "/" },
                  { label: "Order Status Update", route: "/" },
                  { label: "Sign Off Copy Upload", route: "/" },
                  { label: "Sign Off Copy Missing List", route: "/" },
                  { label: "Issued Order List", route: "/" },
                 
                ],
              },
            { label: "Maintenance Planning", 
                val: [
                  { label: "Planning Desktop", route: "/" },
                  { label: "Repeater Alerts", route: "/" },
                  
                 
                ],
              },
            
          ],
        },
        {
          label: "Parts",
          icon: "mdi-arrange-send-to-back",
          val1: [
            { label:"Parts", val:[
              { label: "New Requisition Entry", route: "/flightData" },
            { label: "Outstanding Requisition List", route: "/acInformation" },
            { label: "Requisition History Search", route: "/acInformation" },
            { label: "Parts Search", route: "/acInformation" },
            { label: "Part Install/Remove", route: "/acInformation" },
            { label: "Life Limited Parts List", route: "/acInformation" },
            { label: "Installed Parts Setup", route: "/acInformation" },
            { label: "Parts Remove Report", route: "/acInformation" },
            { label: "Parts Requisition Approval", route: "/acInformation" },
            ]},
            { label:"Stores", val:[
              { label: "Parts Receiving*", route: "/flightData" },
            { label: "Parts Inspection", route: "/acInformation" },
            { label: "Parts Repair", route: "/acInformation" },
            { label: "Cert-Tag Missing List", route: "/acInformation" },
            { label: "Loaner Parts List", route: "/acInformation" },
            { label: "Stores In/Out Report", route: "/acInformation" },
            { label: "Parts Scrap", route: "/acInformation" },
            { label: "Stores Shelf/Bin Setup", route: "/acInformation" },
            { label: "Part Loan In", route: "/acInformation" },
            { label: "Lookup SAP Parts", route: "/acInformation" },
            { label: "SAP Requisition", route: "/acInformation" },
            { label: "Parts Received Report", route: "/acInformation" },
            { label: "LP Part Change Report", route: "/acInformation" },
            ]},
            { label:"Configuration", val:[
              { label: "Master Config", route: "/flightData" },
            { label: "Part Number Config", route: "/acInformation" },
            { label: "Assembly Setup", route: "/acInformation" },
            { label: "Visual NHA", route: "/acInformation" },
            { label: "Kit Templetes*", route: "/acInformation" },
            { label: "Kit Builder*", route: "/acInformation" },
            { label: "Fly Away Kit", route: "/acInformation" },
            { label: "Warehouse Setup", route: "/acInformation" },
            
            ]},
          ],
        },
        {
          label: "Documents",
          icon: "mdi-file-document-multiple",
          val1: [
            { label: "Tecnical Library", route: "/flightData", val:[
              {label:"Engine Manuals", route:"/manual"}
            ] },
            { label: "Airline Documents", route: "/acInformation", val:[
              {label:"Repairs", route:"/manual"},
              {label:"Alterations", route:"/manual"},
              {label:"Workscope", route:"/manual"},
              {label:"DFDR", route:"/manual"},
              {label:"MIS Reports", route:"/manual"},
              {label:"Maintenance Cotrol Ref", route:"/manual"},
              {label:"Training", route:"/manual"},
              {label:"Audits", route:"/manual"},
              {label:"Delivery Documents", route:"/manual"},
            ] },
            { label: "AC Records", route: "/acInformation", val:[
              {label:"Missing Records", route:"/manual"},
              
            ] },
          ],
        },
        {
          label: "Compliance",
          icon: "mdi-apps-box",
          val1: [
            { label: "AD/SB", route: "/flightData", val:[
              {label:"New AD Entry", route:"/manual"},
              {label:"AD Setup", route:"/manual"},
              {label:"New SB Entry", route:"/manual"},
              {label:"SB Setup", route:"/manual"},
              {label:"SB Evaluation History", route:"/manual"},
              {label:"SB Evaluation To Do List", route:"/manual"},
              {label:"SB Pending List", route:"/manual"},
              {label:"SB Master List", route:"/manual"},
              {label:"AD/SB Compliance", route:"/manual"},
              {label:"AD/SB Forest", route:"/manual"},
              {label:"AD Master List", route:"/manual"},
            ] },
            { label: "Reliability", route: "/acInformation", val:[
              {label: "Add Tecnical Delay", route: "/acInformation"},
              {label: "APU In Flight Report", route: "/acInformation"},
              {label: "Flight Hours Per Month", route: "/acInformation"},
              {label: "IFSD Report", route: "/acInformation"},
              {label: "Reliability Report Search", route: "/acInformation"},
              {label: "ADD IDSD", route: "/acInformation"},
            ] },
            { label: "AD Compliance", route: "/acInformation", val:[
              {label: "Airframe", route:"/airframe"},
              {label: "Engine", route:"/airframe"},
              {label: "Appliance", route:"/airframe"},
            ] },
            { label: "Certification", route: "/acInformation", val:[
              {label: "New Certificate", route:"/airframe"},
              {label: "New Tecnician", route:"/airframe"},
              {label: "Assign Certificate", route:"/airframe"},
              {label: "View Certificate Assignments", route:"/airframe"},
              {label: "Search", route:"/airframe"},
              {label: "Edit Certificates", route:"/airframe"},
              {label: "Edit Technician", route:"/airframe"},
            ] },
          ],
        },
        {
          label: "Administration",
          icon: "mdi-apps-box",
          val1: [
            { label: "User Information Setup", route: "/users" },
           /*  { label: "Issued Order", route: "/acInformation" },
            { label: "Outstanding Order Search", route: "/acInformation" }, */
          ],
        },
      ],
       }
   }

}
</script>

<style>

</style>