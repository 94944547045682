<template>
    <div>
    <cabeca></cabeca>

     <v-container grid-list-xs>
         
         <v-card
            class="mx-auto pa-2 px-4 mb-10"
            outlined
            color="white"
        >
        <v-card-title class="px-0" primary-title>
            Aircraft Technical Log
        </v-card-title>

        <v-divider></v-divider>
            <v-row class="mt-5">
               <v-col class="pa-0 px-1" cols="3">
                <v-autocomplete v-model="atl.reg_number" item-value="reg_no" item-text="reg_no" :items="$store.state.acInformation"  dense outlined label="*REG NO">
                  
                </v-autocomplete>
               </v-col>
               <v-col class="pa-0 px-1" cols="3">
                   <v-text-field
                       label="*Log Page NO"
                       v-model="log_page_number"
                       dense outlined
                   ></v-text-field>
                
               </v-col>
               <v-col class="pa-0 px-1 justify-center" cols="2">
                    <v-checkbox
                    class="pa-0 mt-2"
                    v-model="atl.maintenance_only"
                    label="Maintenance Only"

                    @change="changeFlightNumber(val)"
                    ></v-checkbox>
               </v-col>
               <v-col class="pa-0 px-1 justify-center" cols="2">
                    <v-checkbox
                    class="pa-0 mt-2"
                    v-model="atl.voidLogPage"
                    label="Void Log Page"
                    ></v-checkbox>
               </v-col>
            </v-row>
            <v-row class="text-center justify-center info white--text align-center ">
               <p class="ma-0 py-1"> Aircraft Log Entry 1</p>
            </v-row>
            
           <v-col>
<!-- 1 ------------------------------------------------------------------------------------------------------------ -->
               <v-row >
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                        <v-text-field :disabled="enable"  v-model="atl.fligh_number"  label="*Flight No" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                        <v-text-field v-model="atl.department"  label="*Dep" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                        <v-text-field  v-model="atl.arr" label="*Arr" dense outlined   ></v-text-field>
                    </v-col>
               </v-row>


<!-- 1 ------------------------------------------------------------------------------------------------------------ -->
                <v-row cols="6">
                   
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                        <v-text-field  v-model="atl.date" @mousemove="atualizar" @formchange="atualizar" @click="atualizar" @change="atualizar" label="*Take Off Date" type="date" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1"  >
                        <v-text-field  v-model="atl.date2" disabled  label="*Landing Date" type="date" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field  @keyup="atualizar" v-model="atl.block_off" type="time"  label="*BlockOff"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field @keyup="atualizar" v-model="atl.t_o_gmt"  type="time" label="*T/O (GMT)"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field @keyup="atualizar" v-model="atl.ldg_gmt"  type="time" label="*LDG (GMT)"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field @keyup="atualizar" v-model="atl.block_on"  type="time" label="*BlockOn"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:120px;">
                        <v-text-field disabled v-model="atl.block_time"  label="*Block Time"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:120px;">
                        <v-text-field  disabled v-model="atl.flight_time"  label="*Flight Time"  dense outlined   ></v-text-field>
                    </v-col>

                </v-row>

<!-- 1 ------------------------------------------------------------------------------------------------------------ -->
                <v-row cols="6">
                    <v-col class="pa-0 ma-0 mt-0 mx-1" style="max-width:100px;">
                        Etops
                         <v-checkbox
                         class="ma-0"
                        v-model="atl.etops"
                        label="Etops"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                         RVSM
                        <v-checkbox label="RVSM"  class="ma-0" v-model="atl.rvsm" ></v-checkbox>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                        CATII/III APPR
                        <v-checkbox
                        class="ma-0"
                        v-model="atl.catii_iii_appr"
                        :label="`${atl.catii_iii_appr?'Yes':'No'}`"
                        ></v-checkbox>
                    </v-col>

                    <v-col class="pa-0 ma-0 mt-0 mx-1" style="max-width:220px;">
                        <v-radio-group v-model="atl.manual_land" row class="pa-0 ma-0">
                            <v-radio
                            
                                label="Manual Landing"
                                value="true"
                            ></v-radio>
                            <v-radio
                            
                                label="Auto Landing"
                                value="false"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>

              
                   
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field @keyup="atualizar2" v-model="atl.fuel_on_board"  label="Fuel On Board"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field @keyup="atualizar2" v-model="atl.fuel_used" label="Fuel Used"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field  disabled v-model="atl.fuel_remaining" label="Fuel Remaining"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field  v-model="atl.fuel_added" label="Fuel Added"  dense outlined   ></v-text-field>
                    </v-col>

                </v-row>

<!-- 1 ------------------------------------------------------------------------------------------------------------ -->

                <v-row>
                    <v-col cols="6" class="ma-0 pa-0 mt-2">
                        *OIL ADD (QUARTS)
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 mt-2 b-l pl-2">
                         *HYD ADD(QUARTS)
                    </v-col>
                </v-row>

                 <v-row cols="12"  >
                        <v-col cols="6"  class="d-flex">
                            
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.engine1"  label="*Eng1" dense outlined   ></v-text-field>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.engine2" label="*Eng2" dense outlined   ></v-text-field>
                            </v-col>
                            <!-- <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.helice1" label="*Helice1" dense outlined   ></v-text-field>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.helice2" label="*Helice2" dense outlined   ></v-text-field>
                            </v-col> -->
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.apu" label="*APU"  dense outlined   ></v-text-field>
                            </v-col>
                            
                            

                        </v-col>
                        <v-col cols="6" class="d-flex b-l">
                            
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field   v-model="atl.hydra_add1" label="*A" dense outlined   ></v-text-field>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.hydra_add2" label="*B" dense outlined   ></v-text-field>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.h_helice1" label="*C" dense outlined   ></v-text-field>
                            </v-col>
                            <!-- <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.h_helice2" label="*Helice2" dense outlined   ></v-text-field>
                            </v-col> -->
                           
                        </v-col>
                        
                 </v-row>


<!-- 1 ------------------------------------------------------------------------------------------------------------ -->

                <v-row class="mt-2 ">
                
                    <v-col class="pa-0 ma-0 mt-0 mx-1" cols="2" >
                        <v-checkbox
                        class="ma-0"
                        v-model="atl.apu_inflight_start"
                        labe
                        :label="`APU Inflight Start ${apInflightStart?'Yes':'No'}`"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" cols="1" >
                        <v-checkbox
                        class="ma-0"
                        v-model="atl.attempted"
                        :label="`Attempted ${attempted?'Yes':'No'}`"
                        ></v-checkbox>
                    </v-col>

                     <v-col class="pa-0 px-1 ml-3" cols="2">
                        <v-autocomplete v-model="atl.leg" :items="leg" dense outlined label="*Leg">
                        </v-autocomplete>
                    </v-col>
                
                </v-row>





   <v-row class="text-center justify-center info white--text align-center ">
        <p class="ma-0 py-1"> Aircraft Log Entry 2</p>
    </v-row>





               
<!-- 2 ------------------------------------------------------------------------------------------------------------ -->
               <v-row >
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                        <v-text-field :disabled="enable2"  v-model="atl2.fligh_number"  label="*Flight No 2" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                        <v-text-field v-model="atl2.department"  label="*Dep 2" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                        <v-text-field  v-model="atl2.arr" label="*Arr 2" dense outlined   ></v-text-field>
                    </v-col>
               </v-row>

<!-- 2 ------------------------------------------------------------------------------------------------------------ -->
                <v-row cols="6">
                   
                    <v-col class="pa-0 ma-0 mt-2 mx-1" >
                    <v-text-field  v-model="atl2.date" @mousemove="atualizar3" @formchange="atualizar3" @click="atualizar3" @change="atualizar3" label="*Take Off Date 2" type="date" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1"  >
                        <v-text-field  v-model="atl2.date2" disabled  label="*Landing Date 2" type="date" dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field  @keyup="atualizar2" v-model="atl2.block_off" type="time"  label="*BlockOff 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field @keyup="atualizar3" v-model="atl2.t_o_gmt"  type="time" label="*T/O (GMT) 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field @keyup="atualizar3" v-model="atl2.ldg_gmt"  type="time" label="*LDG (GMT) 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:90px;">
                        <v-text-field @keyup="atualizar3" v-model="atl2.block_on"  type="time" label="*BlockOn 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:120px;">
                        <v-text-field disabled v-model="atl2.block_time"  label="*Block Time 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-2 mx-1" style="max-width:120px;">
                        <v-text-field  disabled v-model="atl2.flight_time"  label="*Flight Time 2"  dense outlined   ></v-text-field>
                    </v-col>

                </v-row>



<!-- 2 ------------------------------------------------------------------------------------------------------------ -->
                <v-row cols="6">
                    <v-col class="pa-0 ma-0 mt-0 mx-1" style="max-width:100px;">
                        Etops 2
                         <v-checkbox
                         class="ma-0"
                        v-model="atl2.etops"
                        label="Etops 2"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="6" class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                         RVSM 2
                        <v-checkbox label="RVSM 2"  class="ma-0" v-model="atl2.rvsm" ></v-checkbox>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" style="max-width:110px;">
                        CATII/III APPR 2
                        <v-checkbox
                        class="ma-0"
                        v-model="atl2.catii_iii_appr"
                        :label="`${atl2.catii_iii_appr?'Yes':'No'}`"
                        ></v-checkbox>
                    </v-col>

                    <v-col class="pa-0 ma-0 mt-0 mx-1" style="max-width:220px;">
                        <v-radio-group v-model="atl2.manual_land" row class="pa-0 ma-0">
                            <v-radio
                            
                                label="Manual Landing 2"
                                value="true"
                            ></v-radio>
                            <v-radio
                            
                                label="Auto Landing 2"
                                value="false"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>

                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field @keyup="atualizar4" v-model="atl2.fuel_on_board"  label="Fuel On Board 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field @keyup="atualizar4" v-model="atl2.fuel_used" label="Fuel Used 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field  disabled v-model="atl2.fuel_remaining" label="Fuel Remaining 2"  dense outlined   ></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" >
                        <v-text-field  v-model="atl2.fuel_added" label="Fuel Added 2"  dense outlined   ></v-text-field>
                    </v-col>

                </v-row>


                <v-divider></v-divider>

<!-- 2 ------------------------------------------------------------------------------------------------------------ -->
                <v-row>
                    <v-col cols="6" class="ma-0 pa-0">
                        *OIL ADD (QUARTS) 2
                    </v-col>
                    <v-col cols="6" class="ma-0 pa-0 b-l pl-2">
                         *HYD ADD(QUARTS) 2
                    </v-col>
                </v-row>
                <v-row cols="12"  >
                        <v-col cols="6"  class="d-flex">
                            
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl2.engine1"  label="*Eng1" dense outlined   ></v-text-field>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl2.engine2" label="*Eng2" dense outlined   ></v-text-field>
                            </v-col>
                          
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl2.apu" label="*APU"  dense outlined   ></v-text-field>
                            </v-col>
                            

                        </v-col>
                        <v-col cols="6" class="d-flex b-l">
                            
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field   v-model="atl2.hydra_add1" label="*A" dense outlined   ></v-text-field>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl2.hydra_add2" label="*B" dense outlined   ></v-text-field>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl2.h_helice1" label="*C" dense outlined   ></v-text-field>
                            </v-col>
                            <!-- <v-col class="pa-0 ma-0 mt-2 mx-1" >
                                <v-text-field  v-model="atl.h_helice2" label="*Helice2" dense outlined   ></v-text-field>
                            </v-col> -->
                           
                        </v-col>
                        
                </v-row>
                <v-divider></v-divider>

                <v-row>
                    
                </v-row>

<!-- 2 ------------------------------------------------------------------------------------------------------------ -->
                <v-row class="mt-2 ">
                
                    <v-col class="pa-0 ma-0 mt-0 mx-1" cols="2" >
                        <v-checkbox
                        class="ma-0"
                        v-model="atl2.apu_inflight_start"
                        labe
                        :label="`APU Inflight Start 2 ${atl2.apu_inflight_start?'Yes':'No'}`"
                        ></v-checkbox>
                    </v-col>
                    <v-col class="pa-0 ma-0 mt-0 mx-1" cols="1" >
                        <v-checkbox
                        class="ma-0"
                        v-model="atl2.attempted"
                        :label="`Attempted 2 ${attempted?'Yes':'No'}`"
                        ></v-checkbox>
                    </v-col>

                     <v-col class="pa-0 px-1 ml-3" cols="2">
                        <v-autocomplete v-model="atl2.leg" :items="leg" dense outlined label="*Leg 2">
                        </v-autocomplete>
                    </v-col>
                
                </v-row>

                <v-divider></v-divider>
<!-- ############################################################################################################################################# -->
                  <v-row class="">
                      <v-col cols="6" class="ma-0 pa-0">
                          <div class="info py-1 text-center white--text">Defect Report</div>
                        <v-row class="px-2">
                            <v-col cols="3">
                               
                                 <v-autocomplete dense outlined label="ATA CHAPTER"  :items="$store.state.ata" v-model="atl.ata_chapter1" item-text="chapter" item-value="chapter" >
                                        <template v-slot:item="{ item }" >
                                            <v-list-item-content @click="getAtaSection(item.chapter)">
                                                {{item.chapter}} - {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                 </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                 <v-autocomplete v-model="atl.ata_section1" :items="$store.state.ataSection" item-text="designation" item-value="designation" dense outlined label="*ATA SECTION">
                                        <template v-slot:item="{ item }" >
                                            <v-list-item-content>
                                                {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="atl.stn1" class="mx-2"   label="*STA"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row >
                        <v-row class="pl-3 pr-5 py-0">
                            <v-textarea
                            class="mx-2 my-0"
                            label="*Discrepancy"
                            rows="4"
                            outlined
                            v-model="atl.discrepancy1"
                            ></v-textarea>
                        </v-row>

                        <v-row class="pl-3 pr-5">
                            <v-col cols="6" class="ma-0 pa-0">
                                 <v-text-field class="mx-2" v-model="atl.pn_off1"   label="PN Off"  dense outlined   ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                 <v-text-field class="mx-2" v-model="atl.sn_off1"   label="SN Off"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pl-5 pr-5">
                            <v-col cols="6" class="ma-0 pa-0">
                                 

                                <v-autocomplete dense outlined label="DEFECT TYPE" :items="$store.state.defectType" v-model="atl.defect_type1" item-text="designation" item-value="designation" >
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                 </v-autocomplete>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                 <v-text-field class="mx-2"  v-model="atl.discovered_by1"  label="Discovered By"  dense outlined   ></v-text-field>
                            </v-col>
                           <!--  <v-col cols="4" class="ma-0 pa-0">
                                 <v-text-field class="mx-2" v-model="atl.defect_date" type="date"   label="Defect Date"  dense outlined   ></v-text-field>
                            </v-col> -->
                            
                        </v-row>
                        <v-row class="pl-5 pr-5">
                            
                            <v-col cols="6" class="ma-0 pa-0">
                                 <v-text-field class="mx-2" v-model="atl.defect_date" type="date"   label="Defect Date"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row>
                      </v-col>


                      <v-col cols="6" class="ma-0 pa-0 b-l">
                          <div class="info py-1 text-center white--text">Action Taken</div>
                        <v-row class="px-2">
                            <v-col cols="3">
                               

                                <v-autocomplete dense outlined label="ATA CHAPTER" :items="$store.state.ata" v-model="atl.a_ata_chapter1" item-text="chapter" item-value="chapter" >
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content  @click="getAtaSection2(item.chapter)">
                                                {{item.chapter}} - {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                 </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                 
                                <v-autocomplete v-model="atl.a_ata_section1" :items="$store.state.ataSection2" item-text="designation" item-value="designation" dense outlined label="*ATA SECTION">
                                      <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field class="mx-2" v-model="atl.a_stn1"   label="*STA"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row >
                        <v-row class="pl-3 pr-5 py-0">
                            <v-textarea
                            class="mx-2 my-0"
                            label="Corrective Action"
                            rows="4"
                            v-model="atl.a_corrective_action"
                            outlined
                            ></v-textarea>
                        </v-row>

                        <v-row class="pl-3 pr-5">
                            <v-col cols="4" class="ma-0 pa-0">
                                 <v-text-field class="mx-2"  v-model="atl.a_pn_on"  label="PN On"  dense outlined   ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="ma-0 pa-0">
                                 <v-text-field class="mx-2"  v-model="atl.a_sn_on"  label="SN On"  dense outlined   ></v-text-field>
                            </v-col>

                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_etops_related"
                                :label="`ETOPS related ${atl.a_etops_related?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="pl-3 pr-5">
                            
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_auto_related"
                                :label="`AUTOLAND related ${atl.a_auto_related?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_engine_related"
                                :label="`Engine related ${atl.a_engine_related?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_deferred"
                                :label="`Deferred ${atl.a_deferred?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_rii"
                                :label="`RII ${atl.a_rii?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                           
                        </v-row>
                        <v-row class="pl-5 pr-5">

                             <v-autocomplete  v-if="atl.a_deferred" v-model="atl.deferred" :items="$store.state.dmiRequestO" item-text="airline_dmi_no" item-value="airline_dmi_no" dense outlined label="DMI">
                        
                            </v-autocomplete>

                        </v-row>
                        <v-row class="pl-5 pr-5">
                           
                            <v-col cols="6" class="ma-0 pa-0">
                                 <v-text-field class="mx-2" v-model="atl.a_corrected_by"  label="Corrected By"  dense outlined   ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                 <v-text-field class="mx-2"  v-model="atl.a_rectified_date" style="date" type="date" label="Rectified Date"  dense outlined   ></v-text-field>
                            </v-col>
                            
                        </v-row>
                      </v-col>
                     
                  </v-row>
<!-- ############################################################################################################################################# -->
                    <v-row class="">
                        <v-col cols="6" class="ma-0 pa-0">
                            <div class="info py-1 text-center white--text">Defect Report 2</div>
                        <v-row class="px-2">
                            <v-col cols="3">
                                
                                    <v-autocomplete dense outlined label="ATA CHAPTER"  :items="$store.state.ata" v-model="atl.ata_chapter2" item-text="chapter" item-value="chapter" >
                                        <template v-slot:item="{ item }" >
                                            <v-list-item-content @click="getAtaSection3(item.chapter2)">
                                                {{item.chapter}} - {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                    <v-autocomplete v-model="atl.ata_section2" :items="$store.state.ataSection" item-text="designation" item-value="designation" dense outlined label="*ATA SECTION">
                                        <template v-slot:item="{ item }" >
                                            <v-list-item-content>
                                                {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field v-model="atl.stn2" class="mx-2"   label="*STA"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row >
                        <v-row class="pl-3 pr-5 py-0">
                            <v-textarea
                            class="mx-2 my-0"
                            label="*Discrepancy"
                            rows="4"
                            outlined
                            v-model="atl.discrepancy2"
                            ></v-textarea>
                        </v-row>

                        <v-row class="pl-3 pr-5">
                            <v-col cols="6" class="ma-0 pa-0">
                                    <v-text-field class="mx-2" v-model="atl.pn_off2"   label="PN Off"  dense outlined   ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                    <v-text-field class="mx-2" v-model="atl.sn_off2"   label="SN Off"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="pl-5 pr-5">
                            <v-col cols="6" class="ma-0 pa-0">
                                    

                                <v-autocomplete dense outlined label="DEFECT TYPE" :items="$store.state.defectType" v-model="atl.defect_type2" item-text="designation" item-value="designation" >
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                    <v-text-field class="mx-2"  v-model="atl.discovered_by2"  label="Discovered By"  dense outlined   ></v-text-field>
                            </v-col>
                            <!--  <v-col cols="4" class="ma-0 pa-0">
                                    <v-text-field class="mx-2" v-model="atl.defect_date" type="date"   label="Defect Date"  dense outlined   ></v-text-field>
                            </v-col> -->
                            
                        </v-row>
                        <v-row class="pl-5 pr-5">
                            
                            <v-col cols="6" class="ma-0 pa-0">
                                    <v-text-field class="mx-2" v-model="atl.defect_date2" type="date"   label="Defect Date"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row>
                        </v-col>


                        <v-col cols="6" class="ma-0 pa-0 b-l">
                            <div class="info py-1 text-center white--text">Action Taken 2</div>
                        <v-row class="px-2">
                            <v-col cols="3">
                                

                                <v-autocomplete dense outlined label="ATA CHAPTER" :items="$store.state.ata" v-model="atl.a_ata_chapter2" item-text="chapter" item-value="chapter" >
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content  @click="getAtaSection4(item.chapter)">
                                                {{item.chapter}} - {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                    </v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                                    
                                <v-autocomplete v-model="atl.a_ata_section2" :items="$store.state.ataSection2" item-text="designation" item-value="designation" dense outlined label="*ATA SECTION">
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content>
                                                {{item.designation}}
                                            </v-list-item-content>
                                        </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="3">
                                <v-text-field class="mx-2" v-model="atl.a_stn2"   label="*STA"  dense outlined   ></v-text-field>
                            </v-col>
                        </v-row >
                        <v-row class="pl-3 pr-5 py-0">
                            <v-textarea
                            class="mx-2 my-0"
                            label="Corrective Action"
                            rows="4"
                            v-model="atl.a_corrective_action2"
                            outlined
                            ></v-textarea>
                        </v-row>

                        <v-row class="pl-3 pr-5">
                            <v-col cols="4" class="ma-0 pa-0">
                                    <v-text-field class="mx-2"  v-model="atl.a_pn_on2"  label="PN On"  dense outlined   ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="ma-0 pa-0">
                                    <v-text-field class="mx-2"  v-model="atl.a_sn_on2"  label="SN On"  dense outlined   ></v-text-field>
                            </v-col>

                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_etops_related2"
                                :label="`ETOPS related ${atl.a_etops_related2?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row class="pl-3 pr-5">
                            
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_auto_related2"
                                :label="`AUTOLAND related ${atl.a_auto_related2?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_engine_related2"
                                :label="`Engine related ${atl.a_engine_related2?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_deferred2"
                                :label="`Deferred ${atl.a_deferred2?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                            <v-col class="pa-0 ma-0 mt-0 mx-1" >
                                <v-checkbox
                                dense
                                class="ma-0"
                                v-model="atl.a_rii2"
                                :label="`RII ${atl.a_rii2?'Yes':'No'}`"
                                ></v-checkbox>
                            </v-col>
                            
                        </v-row>
                        <v-row class="pl-5 pr-5">

                                <v-autocomplete  v-if="atl.a_deferred2" v-model="atl.deferred2" :items="$store.state.dmiRequestO" item-text="airline_dmi_no" item-value="airline_dmi_no" dense outlined label="DMI">
                        
                            </v-autocomplete>

                        </v-row>
                        <v-row class="pl-5 pr-5">
                            
                            <v-col cols="6" class="ma-0 pa-0">
                                    <v-text-field class="mx-2" v-model="atl.a_corrected_by2"  label="Corrected By"  dense outlined   ></v-text-field>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                                    <v-text-field class="mx-2"  v-model="atl.a_rectified_date2" style="date" type="date" label="Rectified Date"  dense outlined   ></v-text-field>
                            </v-col>
                            
                        </v-row>
                        </v-col>
                        
                    </v-row>
<!-- ################################################################################################################################################# -->
                  <v-row>
                        <v-file-input
                            label="File input"
                            outlined
                            dense
                            v-model="file"
                            prepend-icon=""
                            prepend-inner-icon="mdi-file"
                            :change="getFile(file)"
                        ></v-file-input>

                        <v-btn color="info" elevation="0" class="ml-2">Cancel</v-btn>
                        <v-btn color="primary" @click="save" elevation="0" class="ml-2">Save</v-btn>
                  </v-row>
           </v-col>
            
      
        </v-card>


     </v-container> 

    </div>
</template>

<script>
import cabeca from "@/components/cabeca";
export default {
  components: {
    cabeca,
  },
  methods:{
      changeFlightNumber(){
          this.atl.fligh_number = (this.atl.maintenance_only)?'MAINT':'';
          this.enable =  !this.enable;
      },
      atualizar(){

        let h1;
        let h2;
        let h3;
        let h4;

        h1 =  this.atl.t_o_gmt.toString().split(":");
        h2 = this.atl.ldg_gmt.toString().split(":");
        h3 =  this.atl.block_off.toString().split(":");
        h4 = this.atl.block_on.toString().split(":");
    
          console.info(h1, h2);
          if(!h1[1])
            h1[1] = "00";
          if(!h2[1])
            h2[1] = "00";

          if(!h3[1])
            h3[1] = "00";
          if(!h4[1])
            h4[1] = "00";
            
         let d1 = null;
         let d2 = null;
         

         
        d1 = new Date(this.atl.date.toString() + " " + h1.join(':') + ":00");
        d2 =  new Date(this.atl.date.toString() + " " + h2.join(':') + ":00");

        let tmpDate = d1;

    

        this.atl.date2 = this.atl.date;

            console.info("Flight Date" , this.atl.date);

          if( d1>d2){
              tmpDate.setHours((d1.getHours()+24))
              let tmp = tmpDate.toLocaleString().toString().split(',');
              let tmpData = [tmp.toString().split('/')[2],tmp.toString().split('/')[1],tmp.toString().split('/')[0]];

              let finalDate =(tmpData[0].split(',')[0] + '-' + tmpData[1] + '-' + tmpData[2]);
              
              console.log("TMP DATE:", finalDate);
              this.atl.date2 = finalDate.toLocaleString();

             /*  alert("dia Seguionte"); */
          }

        try {
            d1 = new Date(this.atl.date.toString() + " " + h1.join(':') + ":00");
            d2 =  new Date(this.atl.date2.toString() + " " + h2.join(':') + ":00");
         } catch (error) {
             console.info('')
         }

        

        let res = new Date(d2-d1)
        res.setHours(res.getHours()-1)

        this.atl.flight_time = ((res.getHours().toString().length<2)?"0"+res.getHours().toString():res.getHours().toString()) + ':' + ((res.getMinutes().toString().length<2)?"0"+res.getMinutes().toString():res.getMinutes().toString());

        /* BLOCK OFF############################################## */

        let d3 = null;
        let d4 = null;
        /* let s = new Date(this.atl.date.toString()); */
        try {
                d3= new Date(this.atl.date.toString() + " " + h3.join(':') + ":00");
                d4 =  new Date(this.atl.date2.toString() + " " + h4.join(':') + ":00");
            } catch (error) {
                console.info('')
            }

        let blockTime = new Date(d4-d3)
        blockTime.setHours(blockTime.getHours()-1);
        // let blockTime = new Date((d3.toString() - d4.toString()))


        console.log("BLOCK TIME", blockTime)
        /* this.atl.flight_time = ((res.getHours().toString().length<2)?"0"+res.getHours().toString():res.getHours().toString()) + ':' + ((res.getMinutes().toString().length<2)?"0"+res.getMinutes().toString():res.getMinutes().toString());
        */

        this.atl.block_time = ((blockTime.getHours().toString().length<2)?"0"+blockTime.getHours().toString():blockTime.getHours().toString()) + ':' + ((blockTime.getMinutes().toString().length<2)?"0"+blockTime.getMinutes().toString():blockTime.getMinutes().toString());
        /* ############################################################### */


      },
      atualizar2(){

          if(parseInt(this.atl.fuel_on_board)>0 && parseInt(this.atl.fuel_used)>0)
            this.atl.fuel_remaining = parseInt(this.atl.fuel_on_board) - parseInt(this.atl.fuel_used)
            else
            this.atl.fuel_remaining = 0

      },

      atualizar3(){

        let h1;
        let h2;
        let h3;
        let h4;

        h1 =  this.atl2.t_o_gmt.toString().split(":");
        h2 = this.atl2.ldg_gmt.toString().split(":");
        h3 =  this.atl2.block_off.toString().split(":");
        h4 = this.atl2.block_on.toString().split(":");
    
          console.info(h1, h2);
          if(!h1[1])
            h1[1] = "00";
          if(!h2[1])
            h2[1] = "00";

          if(!h3[1])
            h3[1] = "00";
          if(!h4[1])
            h4[1] = "00";
            
         let d1 = null;
         let d2 = null;
         

         
        d1 = new Date(this.atl2.date.toString() + " " + h1.join(':') + ":00");
        d2 =  new Date(this.atl2.date.toString() + " " + h2.join(':') + ":00");

        let tmpDate = d1;

    

        this.atl2.date2 = this.atl2.date;

            console.info("Flight Date" , this.atl2.date);

          if( d1>d2){
              tmpDate.setHours((d1.getHours()+24))
              let tmp = tmpDate.toLocaleString().toString().split(',');
              let tmpData = [tmp.toString().split('/')[2],tmp.toString().split('/')[1],tmp.toString().split('/')[0]];

              let finalDate =(tmpData[0].split(',')[0] + '-' + tmpData[1] + '-' + tmpData[2]);
              
              console.log("TMP DATE:", finalDate);
              this.atl2.date2 = finalDate.toLocaleString();

             /*  alert("dia Seguionte"); */
          }

        try {
            d1 = new Date(this.atl2.date.toString() + " " + h1.join(':') + ":00");
            d2 =  new Date(this.atl2.date2.toString() + " " + h2.join(':') + ":00");
         } catch (error) {
             console.info('')
         }

        

        let res = new Date(d2-d1)
        res.setHours(res.getHours()-1)

        this.atl2.flight_time = ((res.getHours().toString().length<2)?"0"+res.getHours().toString():res.getHours().toString()) + ':' + ((res.getMinutes().toString().length<2)?"0"+res.getMinutes().toString():res.getMinutes().toString());

        /* BLOCK OFF############################################## */

        let d3 = null;
        let d4 = null;
        /* let s = new Date(this.atl2.date.toString()); */
        try {
                d3= new Date(this.atl2.date.toString() + " " + h3.join(':') + ":00");
                d4 =  new Date(this.atl2.date2.toString() + " " + h4.join(':') + ":00");
            } catch (error) {
                console.info('')
            }

        let blockTime = new Date(d4-d3)
        blockTime.setHours(blockTime.getHours()-1);
        // let blockTime = new Date((d3.toString() - d4.toString()))


        console.log("BLOCK TIME", blockTime)
        /* this.atl2.flight_time = ((res.getHours().toString().length<2)?"0"+res.getHours().toString():res.getHours().toString()) + ':' + ((res.getMinutes().toString().length<2)?"0"+res.getMinutes().toString():res.getMinutes().toString());
        */

        this.atl2.block_time = ((blockTime.getHours().toString().length<2)?"0"+blockTime.getHours().toString():blockTime.getHours().toString()) + ':' + ((blockTime.getMinutes().toString().length<2)?"0"+blockTime.getMinutes().toString():blockTime.getMinutes().toString());
        /* ############################################################### */


      },
      atualizar4(){

          if(parseInt(this.atl2.fuel_on_board)>0 && parseInt(this.atl2.fuel_used)>0)
            this.atl2.fuel_remaining = parseInt(this.atl2.fuel_on_board) - parseInt(this.atl2.fuel_used)
            else
            this.atl2.fuel_remaining = 0

      },

      clear(){
          this.atl = {
            /* reg_number:'',  */
              log_page_number:'', 
              maintenance_only:'', 
              fligh_number:'', 
              department:'', 
              arr:'', 
              block_off:'', 
              date:'', 
              date2:'', 
              t_o_gmt:'', 
              ldg_gmt:'', 
              block_on:'', 
              block_time:'', 
              flight_time:'', 
              etops:'', 
              rvsm:'', 
              catii_iii_appr:'', 
              manual_land:'', 
              fuel_on_board:0, 
              fuel_used:0, 
              fuel_remaining:0, 
              fuel_added:0, 
              engine1:'', 
              engine2:'', 
              helice1:'', 
              helice2:'', 
              apu:'', 
              hydra_add1:'', 
              hydra_add2:'', 
              h_helice1:'', 
              h_helice2:'', 
              hours_total:0, 
              minutes_total:0, 
              cycles_total:0, 
              apu_inflight_start:'', 
              attempted:'', 
              leg:'', 
              ata_chapter1:'', 
              ata_section1:'', 
              stn1:'', 
              discrepancy1:'', 
              pn_off1:'', 
              sn_off1:'', 
              defect_type1:'', 
              discovered_by1:'', 
              defect_date:'', 
              a_ata_chapter1:'', 
              a_ata_section1:'', 
              a_stn1:'', 
              a_corrective_action:'', 
              a_pn_on:'', 
              a_sn_on:'', 
              a_etops_related:'', 
              a_auto_related:'', 
              a_engine_related:'', 
              deferred:'', 
              a_deferred:'', 
              a_rii:'', 
              a_corrected_by:'', 
              a_rectified_date:'', 
              ata_chapter2:'', 
              ata_section2:'', 
              stn2:'', 
              discrepancy2:'', 
              pn_off2:'', 
              sn_off2:'', 
              defect_type2:'', 
              discovered_by2:'', 
              defect_date2:'', 
              a_ata_chapter2:'', 
              a_ata_section2:'', 
              a_stn2:'', 
              a_corrective_action2:'', 
              a_pn_on2:'', 
              a_sn_on2:'', 
              a_etops_related2:'', 
              a_autoland_related2:'', 
              a_engine_related2:'', 
              a_deferred2:'', 
              a_rii2:'', 
              a_corrected_by2:'', 
              a_rectified_date2:'', 
              certificado:'', 
              status:''
                }
          
          
           this.atl2 ={
            /* reg_number:'',  */
              log_page_number:'', 
              maintenance_only:'', 
              fligh_number:'', 
              department:'', 
              arr:'', 
              block_off:'', 
              date:'', 
              date2:'', 
              t_o_gmt:'', 
              ldg_gmt:'', 
              block_on:'', 
              block_time:'', 
              flight_time:'', 
              etops:'', 
              rvsm:'', 
              catii_iii_appr:'', 
              manual_land:'', 
              fuel_on_board:0, 
              fuel_used:0, 
              fuel_remaining:0, 
              fuel_added:0, 
              engine1:'', 
              engine2:'', 
              helice1:'', 
              helice2:'', 
              apu:'', 
              hydra_add1:'', 
              hydra_add2:'', 
              h_helice1:'', 
              h_helice2:'', 
              hours_total:0, 
              minutes_total:0, 
              cycles_total:0, 
              apu_inflight_start:'', 
              attempted:'', 
              leg:'', 
              ata_chapter1:'', 
              ata_section1:'', 
              stn1:'', 
              discrepancy1:'', 
              pn_off1:'', 
              sn_off1:'', 
              defect_type1:'', 
              discovered_by1:'', 
              defect_date:'', 
              a_ata_chapter1:'', 
              a_ata_section1:'', 
              a_stn1:'', 
              a_corrective_action:'', 
              a_pn_on:'', 
              a_sn_on:'', 
              a_etops_related:'', 
              a_auto_related:'', 
              a_engine_related:'', 
              deferred:'', 
              a_deferred:'', 
              a_rii:'', 
              a_corrected_by:'', 
              a_rectified_date:'', 
              ata_chapter2:'', 
              ata_section2:'', 
              stn2:'', 
              discrepancy2:'', 
              pn_off2:'', 
              sn_off2:'', 
              defect_type2:'', 
              discovered_by2:'', 
              defect_date2:'', 
              a_ata_chapter2:'', 
              a_ata_section2:'', 
              a_stn2:'', 
              a_corrective_action2:'', 
              a_pn_on2:'', 
              a_sn_on2:'', 
              a_etops_related2:'', 
              a_autoland_related2:'', 
              a_engine_related2:'', 
              a_deferred2:'', 
              a_rii2:'', 
              a_corrected_by2:'', 
              a_rectified_date2:'', 
              certificado:'', 
              status:''
                }
      },

      getFile(file){
        console.log(file)
      },
    // Funcao para salver users na bd
    save() {

        let store = this.$store;
        store.dispatch("loading")
        let axios = require("axios");
        const Swal = require("sweetalert2");

        let url = this.$store.state.path + "atl";
        let dis = this;


       
            url += this.edit ? ('/'+this.atl.id.toString()) + "?_method=PATCH" : "";

        

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if(this.atl.maintenance_only || this.atl.voidLogPage){
            this.atl.flight_time = "00:00:00";
            this.atl.block_time = "00:00:00";
            this.atl.block_off = "00:00:00";
            this.atl.t_o_gmt = "00:00:00";
            this.atl.ldg_gmt = "00:00:00";
            this.atl.block_on = "00:00:00";
        }

        
        
        if(!this.edit)
            this.atl.log_page_number = this.log_page_number /* + "-1" */;
        else
            this.atl.log_page_number = this.log_page_number
        
      if(this.atl.block_time){
        this.atl.flight_time = (this.atl.flight_time.toString().split('NaN').length<1)?"00:00:00":this.atl.flight_time;
        this.atl.block_time = (this.atl.block_time.toString().split('NaN').length<1)?"00:00:00":this.atl.block_time;
      }
        

        

    var form_data = new FormData();

    for ( var key in this.atl ) {
        form_data.append(key, this.atl[key]);
    }
     form_data.append('file',this.file);

      console.info("Fiormulario",form_data);

   
      axios
        .post(url, form_data, config)
        .then(function(response) {
            store.dispatch("nloading")
          if (typeof response.data == "string" && !dis.edit)
            Swal.fire(
              "Oops...",
              dis.edit
                ? "There was an error while Updating ATL. "
                : "There was an error while saving. " + response.data ,
              "error"
            );
          else {
              store.dispatch("nloading")
              
            if (!dis.edit) {
              store.state.aircraftLog.push(response.data);
              store.state.atl.push(response.data);
             /*  store.state.acInformation = []; */

              store.dispatch("getAtl");

              Swal.fire(
                "Success!",
                dis.edit
                  ? "New ATL Updated successfuly. "
                  : "ATL Added successfuly.",
                "success"
              );
            } else {
                 /* store.dispatch('getAircraftInformation'); */

              Swal.fire(
                "Success!",
                dis.edit
                  ? "New ATL edited successfuly. "
                  : "ATL Updated successfuly.",
                "success"
              );
            }


            if(dis.atl2.flight_time && dis.atl2.block_time && dis.atl2.fligh_number){
                dis.save2();
            }



           if(!dis.atl.discrepancy1  &&  !dis.atl.a_corrective_action){
               dis.clear();
                return null;
           }
            
                dis.saveLoog();
                if(dis.atl.discrepancy2 && dis.atl.a_corrective_action2)
                dis.saveLoog2();

/* ############################################################################################ */

          }
        })
        .catch(function(error) {
            store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });
    },
    save2() {

        let store = this.$store;
        store.dispatch("loading")
        let axios = require("axios");
        const Swal = require("sweetalert2");

        let url = this.$store.state.path + "atl";
        let dis = this;

        url += this.edit ? ('/'+this.atl2.id.toString()) + "?_method=PATCH" : "";

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        if(this.atl2.maintenance_only || this.atl2.voidLogPage){
            this.atl2.flight_time = "00:00:00";
            this.atl2.block_time = "00:00:00";
            this.atl2.block_off = "00:00:00";
            this.atl2.t_o_gmt = "00:00:00";
            this.atl2.ldg_gmt = "00:00:00";
            this.atl2.block_on = "00:00:00";
        }

        this.atl2.reg_number = this.atl.reg_number;
        this.atl2.maintenance_only = this.atl.maintenance_only;
        this.atl2.log_page_number = this.log_page_number /* + "-2" */;
      
        this.atl2.flight_time = (this.atl2.flight_time.toString().split('NaN').length<1)?"00:00":this.atl2.flight_time;
        this.atl2.block_time = (this.atl2.block_time.toString().split('NaN').length<1)?"00:00":this.atl2.block_time;

        

    var form_data = new FormData();

    for ( var key in this.atl2 ) {
        form_data.append(key, this.atl2[key]);
    }
     form_data.append('file',this.file);


   
      axios
        .post(url, form_data, config)
        .then(function(response) {
            store.dispatch("nloading")
          if (typeof response.data == "string" && !dis.edit)
            Swal.fire(
              "Oops...",
              dis.edit
                ? "There was an error while saving the Aircraft Log Entry 2. "
                : "There was an error while Updating Aircraft Log Entry 2",
              "error"
            );
          else {
              store.dispatch("nloading")
              
            if (!dis.edit) {
              store.state.aircraftLog.push(response.data);
              store.state.atl.push(response.data);
             /*  store.state.acInformation = []; */

              store.dispatch("getAtl");

              Swal.fire(
                "Success!",
                dis.edit
                  ? "Aircraft Log Entry 2 Updated successfuly. "
                  : "Aircraft Log Entry 2 Added successfuly.",
                "success"
              );
            } else {
                 /* store.dispatch('getAircraftInformation'); */

              Swal.fire(
                "Success!",
                dis.edit
                  ? "Aircraft Log Entry 2 edited successfuly. "
                  : "Aircraft Log Entry 2 Updated successfuly.",
                "success"
              );
            }


/* ############################################################################################ */

          }
        })
        .catch(function(error) {
            store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save the Aircraft Log Entry 2. " + error, "error");
          console.info(error);
        });
    },

    saveLoog(){
        
            let store = this.$store;
            let dis = this;

            if(!this.edit)
                this.atl.log_page_number = this.log_page_number + "-1";
            else
                this.atl.log_page_number = this.log_page_number;
            
            let axios = require("axios");
            const Swal = require("sweetalert2");
/* ############################################################################################# */
            let dados = {
            
            internalId: '',
            reg_no: dis.atl.reg_number,
            defect_date: dis.atl.defect_date,
            log_page_number: dis.atl.log_page_number,
            station: dis.atl.stn1,
            ata_section: dis.atl.ata_section1,
            ata_chapter: dis.atl.ata_chapter1,
            discrepancy: dis.atl.discrepancy1,
            vpn: dis.atl.pn_off1,
            stn: dis.atl.sn_off1,
            defect_type: dis.atl.defect_type1,
            discovered_by: dis.atl.discovered_by1,
            deferral: dis.atl.deferred,
            non_routin_defect: '',
            correction_action: dis.atl.a_corrective_action,
            action_station: dis.atl.a_stn1,
            action_vpn: dis.atl.a_pn_on,
            action_sn: dis.atl.a_sn_on,
            action_ata_chapter: dis.atl.a_ata_chapter1,
            action_ata_section: dis.atl.a_ata_section1,
            action_corrected_by: dis.atl.a_corrected_by,
            action_rectified_date: dis.atl.a_rectified_date,
            action_ethops_related: dis.atl.a_etops_related,
            action_autoland_related: dis.atl.a_auto_related,
            action_engine_related: dis.atl.a_engine_related,
            action_rii: dis.atl.a_rii,
           
          };


    const config2 = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    store.dispatch("loading")
    var form_data2 = new FormData();

    for ( var key in dados ) {
        form_data2.append(key, dados[key]);
    }
     form_data2.append('link_to_log',dis.file);



      let  url = dis.$store.state.path + "defectLog";
         if(dis.edit)
        dados.id = dis.atl.id;
        url += dis.edit ?( '/'+dados.id.toString()) + "?_method=PATCH" : "";
       
      axios
        .post(url, form_data2, config2)
        .then(function(response) {
            store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating users",
              "error"
            );
          else {
            if (!dis.edit) {
              store.state.defectLog.push(response.data);
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New Registry added successfuly. "
                  : "Registry Updated successfuly.",
                "success"
              );
              
            } else {

                dis.$store.dispatch('getAtl');
                dis.$store.dispatch('getDefectLog');
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New Registry edited successfuly. "
                  : "Registry Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
            store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });


    },
    saveLoog2(){

        
        let store = this.$store;
            let dis = this;
            let axios = require("axios");
            const Swal = require("sweetalert2");
            if(!this.edit)
            this.atl.log_page_number = this.log_page_number + "-2";
/* ############################################################################################# */
            let dados = {
            
            internalId: '',
            reg_no: dis.atl.reg_number,
            defect_date: dis.atl.defect_date2,
            log_page_number: dis.atl.log_page_number,
            station: dis.atl.stn2,
            ata_section: dis.atl.ata_section2,
            ata_chapter: dis.atl.ata_chapter2,
            discrepancy: dis.atl.discrepancy2,
            vpn: dis.atl.pn_off2,
            stn: dis.atl.sn_off2,
            defect_type: dis.atl.defect_type2,
            discovered_by: dis.atl.discovered_by2,
            deferral: dis.atl.deferred2,
            non_routin_defect: '',
            correction_action: dis.atl.a_corrective_action2,
            action_station: dis.atl.a_stn2,
            action_vpn: dis.atl.a_pn_on2,
            action_sn: dis.atl.a_sn_on2,
            action_ata_chapter: dis.atl.a_ata_chapter2,
            action_ata_section: dis.atl.a_ata_section2,
            action_corrected_by: dis.atl.a_corrected_by2,
            action_rectified_date: dis.atl.a_rectified_date2,
            action_ethops_related: dis.atl.a_etops_related2,
            action_autoland_related: dis.atl.a_auto_related2,
            action_engine_related: dis.atl.a_engine_related2,
            action_rii: dis.atl.a_rii2,
          };


    const config2 = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    store.dispatch("loading")
    var form_data2 = new FormData();

    for ( var key in dados ) {
        form_data2.append(key, dados[key]);
    }
     form_data2.append('link_to_log',dis.file);



      let  url = dis.$store.state.path + "defectLog";
         if(dis.edit)
        dados.id = dis.atl.id;
        url += dis.edit ? ('/'+dados.id.toString()) + "?_method=PATCH" : "";
       
      axios
        .post(url, form_data2, config2)
        .then(function(response) {
            store.dispatch("nloading")
          if (typeof response.data == "string")
            Swal.fire(
              "Oops...",
              !dis.edit
                ? "There was an error while saving. "
                : "There was an error while Updating users",
              "error"
            );
          else {
            if (!dis.edit) {
              store.state.defectLog.push(response.data);
              dis.clear();
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New Registry added successfuly. "
                  : "Registry Updated successfuly.",
                "success"
              );
            } else {

                dis.$store.dispatch('getAtl');
                dis.$store.dispatch('getDefectLog');
              Swal.fire(
                "Success!",
                !dis.edit
                  ? "New Registry edited successfuly. "
                  : "Registry Updated successfuly.",
                "success"
              );
            }
          }
        })
        .catch(function(error) {
            store.dispatch("nloading")
          Swal.fire("Oops...", "Unable to save. " + error, "error");
          console.info(error);
        });


    },

    getAtaSection(val){
      this.$store.dispatch("ataSection",{index:val});
    },
    getAtaSection2(val){
      this.$store.dispatch("ataSection",{index: val,d:2});
    }
  },
  data(){
      return {
          edit:false,
          maintenanceOnly:false,
          voidLogPage:false,
          catii_ii_appr:false,
          manualLand:false,
          autoLand:false,
          attempted:false,
          apInflightStart:false,
          enable:false,
          enable2:false,
          log_page_number:'',

          bf:0,
          bo:0,
          tf:0,
          to:0,
          blocktime:"",
          flightTime:"",

          file:'',

          leg:[
              1,
              2,
              3
          ],

          atl:{
              reg_number:'', 
              log_page_number:'', 
              maintenance_only:'', 
              fligh_number:'', 
              department:'', 
              arr:'', 
              block_off:'', 
              date:'', 
              date2:'', 
              t_o_gmt:'', 
              ldg_gmt:'', 
              block_on:'', 
              block_time:'', 
              flight_time:'', 
              etops:'', 
              rvsm:'', 
              catii_iii_appr:'', 
              manual_land:'', 
              fuel_on_board:0, 
              fuel_used:0, 
              fuel_remaining:0, 
              fuel_added:0, 
              engine1:'', 
              engine2:'', 
              helice1:'', 
              helice2:'', 
              apu:'', 
              hydra_add1:'', 
              hydra_add2:'', 
              h_helice1:'', 
              h_helice2:'', 
              hours_total:0, 
              minutes_total:0, 
              cycles_total:0, 
              apu_inflight_start:'', 
              attempted:'', 
              leg:'', 
              ata_chapter1:'', 
              ata_section1:'', 
              stn1:'', 
              discrepancy1:'', 
              pn_off1:'', 
              sn_off1:'', 
              defect_type1:'', 
              discovered_by1:'', 
              defect_date:'', 
              a_ata_chapter1:'', 
              a_ata_section1:'', 
              a_stn1:'', 
              a_corrective_action:'', 
              a_pn_on:'', 
              a_sn_on:'', 
              a_etops_related:'', 
              a_auto_related:'', 
              a_engine_related:'', 
              deferred:'', 
              a_deferred:'', 
              a_rii:'', 
              a_corrected_by:'', 
              a_rectified_date:'', 
              ata_chapter2:'', 
              ata_section2:'', 
              stn2:'', 
              discrepancy2:'', 
              pn_off2:'', 
              sn_off2:'', 
              defect_type2:'', 
              discovered_by2:'', 
              defect_date2:'', 
              a_ata_chapter2:'', 
              a_ata_section2:'', 
              a_stn2:'', 
              a_corrective_action2:'', 
              a_pn_on2:'', 
              a_sn_on2:'', 
              a_etops_related2:'', 
              a_autoland_related2:'', 
              a_engine_related2:'', 
              a_deferred2:'', 
              a_rii2:'', 
              a_corrected_by2:'', 
              a_rectified_date2:'', 
              certificado:'', 

          },
          atl2:{
              reg_number:'', 
              log_page_number:'', 
              maintenance_only:'', 
              fligh_number:'', 
              department:'', 
              arr:'', 
              block_off:'', 
              date:'', 
              date2:'', 
              t_o_gmt:'', 
              ldg_gmt:'', 
              block_on:'', 
              block_time:'', 
              flight_time:'', 
              etops:'', 
              rvsm:'', 
              catii_iii_appr:'', 
              manual_land:'', 
              fuel_on_board:0, 
              fuel_used:0, 
              fuel_remaining:0, 
              fuel_added:0, 
              engine1:'', 
              engine2:'', 
              helice1:'', 
              helice2:'', 
              apu:'', 
              hydra_add1:'', 
              hydra_add2:'', 
              h_helice1:'', 
              h_helice2:'', 
              hours_total:0, 
              minutes_total:0, 
              cycles_total:0, 
              apu_inflight_start:'', 
              attempted:'', 
              leg:'', 
              ata_chapter1:'', 
              ata_section1:'', 
              stn1:'', 
              discrepancy1:'', 
              pn_off1:'', 
              sn_off1:'', 
              defect_type1:'', 
              discovered_by1:'', 
              defect_date:'', 
              a_ata_chapter1:'', 
              a_ata_section1:'', 
              a_stn1:'', 
              a_corrective_action:'', 
              a_pn_on:'', 
              a_sn_on:'', 
              a_etops_related:'', 
              a_auto_related:'', 
              a_engine_related:'', 
              deferred:'', 
              a_deferred:'', 
              a_rii:'', 
              a_corrected_by:'', 
              a_rectified_date:'', 
              ata_chapter2:'', 
              ata_section2:'', 
              stn2:'', 
              discrepancy2:'', 
              pn_off2:'', 
              sn_off2:'', 
              defect_type2:'', 
              discovered_by2:'', 
              defect_date2:'', 
              a_ata_chapter2:'', 
              a_ata_section2:'', 
              a_stn2:'', 
              a_corrective_action2:'', 
              a_pn_on2:'', 
              a_sn_on2:'', 
              a_etops_related2:'', 
              a_autoland_related2:'', 
              a_engine_related2:'', 
              a_deferred2:'', 
              a_rii2:'', 
              a_corrected_by2:'', 
              a_rectified_date2:'', 
              certificado:'', 

          }
      }
  },

  mounted(){
      this.$store.dispatch('getAta')
      this.$store.dispatch('defectType')
      this.$store.dispatch("getAircraftInformation");
      this.$store.dispatch("getDmiRequestO");

       if(this.$store.state.editing){
           let log = this.$store.state.editingItem;

        if(log.flight_time){
            
            this.atl = {

                id: log.id,
                reg_number: log.reg_number,
                log_page_number: log.log_page_number,
                maintenance_only: (log.maintenance_only!="null")? log.maintenance_only:false,
                fligh_number: log.fligh_number,
                department: (log.department!="null")?log.department:null,
                arr: (log.arr!="null")?log.arr:null,
                block_off: (log.block_off!="null")?log.block_off:null,
                t_o_gmt: (log.t_o_gmt!="null")?log.t_o_gmt:null,
                ldg_gmt: (log.ldg_gmt!="null")?log.ldg_gmt:null,
                block_on: (log.block_on!="null")?log.block_on:null,
                block_time: (log.block_time!="null")?log.block_time:null,
                flight_time: (log.flight_time!="null")?log.flight_time:null,
                etops: (log.etops!="null")?log.etops:false,
                rvsm: (log.rvsm!="null")?log.rvsm:false,
                catii_iii_appr: (log.catii_iii_appr!="null")?log.catii_iii_appr:false,
                manual_land: (log.manual_land!="null")?log.manual_land:false,
                fuel_on_board: (log.fuel_on_board!="null")?log.fuel_on_board:null,
                fuel_used: (log.fuel_used!="null")?log.fuel_used:null,
                fuel_remaining: (log.fuel_remaining!="null")?log.fuel_remaining:null,
                fuel_added: (log.fuel_added!="null")?log.fuel_added:null,
                engine1: (log.engine1!="null")?log.engine1:null,
                engine2: (log.engine2!="null")?log.engine2:null,
                helice1: (log.helice1!="null")?log.helice1:null,
                helice2: (log.helice2!="null")?log.helice2:null,
                apu: (log.apu!="null")?log.apu:null,
                hydra_add1: (log.hydra_add1!="null")?log.hydra_add1:null,
                hydra_add2: (log.hydra_add2!="null")?log.hydra_add2:null,
                h_helice1: (log.h_helice1!="null")?log.h_helice1:null,
                h_helice2: (log.h_helice2!="null")?log.h_helice2:null,
                hours_total: (log.hours_total!="null")?log.hours_total:null,
                minutes_total: (log.minutes_total!="null")?log.minutes_total:null,
                cycles_total: (log.cycles_total!="null")?log.cycles_total:null,
                apu_inflight_start: (log.apu_inflight_start!="null")?log.apu_inflight_start:null,
                attempted: (log.attempted!="null")?log.attempted:null,
                leg: (log.leg!="null")?log.leg:null,
                date:(log.date!="null")?log.date:(log.date2!="null")?log.date2:null, 
                date2:(log.date2!="null")?log.date2:null,

            };

            this.log_page_number = log.log_page_number;
        }
        else{
            this.atl = {
                id:log.id,
                reg_number:log.reg_no, 
                  log_page_number:log.log_page_number, 
                  ata_chapter1:log.ata_chapter, 
                  ata_section1:log.ata_section, 
                  stn1:log.station, 
                  discrepancy1:log.discrepancy, 
                  pn_off1:log.vpn, 
                  sn_off1:log.stn, 
                  defect_type1:log.defect_type, 
                  discovered_by1:log.discovered_by, 
                  defect_date:log.defect_date, 
                  a_ata_chapter1:log.action_ata_chapter, 
                  a_ata_section1:log.action_ata_section, 
                  a_stn1:log.action_station, 
                  a_corrective_action:log.correction_action, 
                  a_pn_on:log.action_vpn, 
                  a_sn_on:log.action_sn, 
                  a_etops_related:log.action_ethops_related, 
                  a_auto_related:log.action_autoland_related, 
                  a_engine_related:log.action_engine_related, 
                  a_deferred:log.deferral, 
                  a_rii:log.action_rii, 
                  a_corrected_by:log.action_corrected_by, 
                  a_rectified_date:log.action_rectified_date, 
            };
            this.log_page_number = log.log_page_number;
        }

        this.edit = true;
        }
  },
 
  


}
</script>

<style>

</style>