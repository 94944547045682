<template>
  <div style="width:100%; height:100vh !important;" class="bg777">

      <div class="fixed">
          <v-col class=" d-flex justify-center align-center mt-10" width="100vh"  height="100%">
          <v-card color="white" class="rounded-lg pa-5 d-flex justify-center align-center" width="850px" min-height="400px"> 
              <v-col class=" align-center d-flex flex-column">

                  <v-img width="200px"  src="@/assets/images/taag3.png">

                  </v-img>

                  <h1>TAAG-VME</h1>
                  <br><br>

                  <h3>OLÁ!<br> O SISTEMA ENCONTRA-SE EM MANUNTENÇÃO, VOLTAMOS BREVEMENTE...</h3>
              

              </v-col>
              
          </v-card>
      </v-col>
      </div>
  </div>
</template>

<script>

export default {

    components:{
    },

    data(){
        return{
            username:'',
            password:''
        }
    },
    methods:{
      login(){


       const axios = require('axios');

       this.$store.dispatch("loading")
       let store =  this.$store;
       let router =  this.$router;
      const Swal = require('sweetalert2');
      

       axios.post(this.$store.state.path+'login', {
          username: this.username,
          password: this.password,
        })
        .then(function (response) {

            if(typeof response.data == 'string')
              Swal.fire({title:"Erro!", text:response.data, icon: 'warning',});
            else{

              store.state.user = response.data;
              sessionStorage.setItem("Username","Filipe Lukebana");
              store.dispatch("login");
               store.state.logged = true;
              router.push("/");
            }

          store.dispatch("nloading")
        })
        .catch(function (error) {
           console.log(error)
           store.dispatch("nloading")
        });

 
       
     }
    }

}
</script>

<style>

</style>